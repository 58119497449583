import React, { useEffect, useState, useCallback } from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
  Link,
} from 'react-router-dom';
import {
  Table,
  Badge,
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import AppPageTitle from '../components/app/AppPageTitle';
import 'bootstrap/dist/css/bootstrap.min.css';
import debounce from 'lodash.debounce';
import './JobSessionsPage.css';
import {
  ChatLeftDotsFill,
  Copy,
  PencilSquare,
  PersonRolodex,
} from 'react-bootstrap-icons';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const pageSize = 15;

const CompanyJobApplicationsPage = () => {
  const { jobId } = useParams();
  const { jobGroupId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams(); // Remove setSearchParams
  const [hasInitialized, setHasInitialized] = useState(false);

  const [applications, setApplications] = useState([]);
  const [hasNoJobGroups, setHasNoJobGroups] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [jobTitle, setJobTitle] = useState(''); // New state for job title
  const [sortColumn, setSortColumn] = useState(
    () => searchParams.get('sortColumn') || 'createdAt'
  );
  const [sortOrder, setSortOrder] = useState(
    () => searchParams.get('sortOrder') || 'DESC'
  );
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');
  const [searchText, setSearchText] = useState(
    () => searchParams.get('searchText') || ''
  );
  const [searchInput, setSearchInput] = useState(
    () => searchParams.get('searchText') || ''
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    () => parseInt(searchParams.get('page')) || 1
  );
  const [totalPages, setTotalPages] = useState(1);

  // Default result filter based on admin status
  const defaultResultFilter = () => {
    return {
      KNOCKOUT_FAILED: true,
      PASSED: true,
      FAILED: true,
      INCOMPLETE: true,
      IN_PROGRESS: true,
      ENGAGED: true,
    };
  };

  // Parse the `resultFilter` query param or initialize default
  const [resultFilter, setResultFilter] = useState(() => {
    const queryFilter = searchParams.get('resultFilter');
    if (queryFilter) {
      try {
        return JSON.parse(queryFilter);
      } catch (error) {
        console.error('Error parsing resultFilter:', error);
        return defaultResultFilter();
      }
    }
    return defaultResultFilter();
  });

  // Add this effect to handle browser navigation (back/forward buttons)
  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search);

      // Update all state from URL parameters
      setCurrentPage(parseInt(params.get('page')) || 1);
      setSearchText(params.get('searchText') || '');
      setSearchInput(params.get('searchText') || '');
      setSortColumn(params.get('sortColumn') || 'createdAt');
      setSortOrder(params.get('sortOrder') || 'DESC');

      // Handle result filter
      const queryFilter = params.get('resultFilter');
      if (queryFilter) {
        try {
          setResultFilter(JSON.parse(queryFilter));
        } catch (error) {
          console.error('Error parsing resultFilter:', error);
          setResultFilter(defaultResultFilter());
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    if (!hasInitialized) {
      setHasInitialized(true);
      return;
    }

    const params = new URLSearchParams(searchParams);

    if (searchText) params.set('searchText', searchText);
    else params.delete('searchText');

    if (currentPage > 1) params.set('page', currentPage);
    else params.delete('page');

    if (sortColumn !== 'createdAt') params.set('sortColumn', sortColumn);
    else params.delete('sortColumn');

    if (sortOrder !== 'DESC') params.set('sortOrder', sortOrder);
    else params.delete('sortOrder');

    if (Object.keys(resultFilter).length > 0) {
      params.set('resultFilter', JSON.stringify(resultFilter));
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [
    searchText,
    currentPage,
    resultFilter,
    sortColumn,
    sortOrder,
    hasInitialized,
    navigate,
    location.pathname,
    searchParams,
  ]);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const apiEndpoint = `${BACKEND_API_URL}/api/applications/paginated`;
        const filters = {
          searchText,
          jobId,
          jobGroupId,
          resultFilter: Object.keys(resultFilter).filter(
            (key) => resultFilter[key] === true
          ),
          sortColumn, // Send the selected column
          sortOrder, // Send the selected order (ASC/DESC)
          page: currentPage,
          pageSize,
        };

        const response = await fetch(apiEndpoint, {
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({ filters }),
        });

        if (!response.ok) throw new Error('Failed to fetch applications');

        const data = await response.json();
        setApplications(data.applications);
        setJobTitle(data.job?.title || 'All Applications');
        setCurrentPage(data.pagination.page || 1);
        setTotalPages(data.pagination.totalPages || 1);
        setIsLoading(false);
        setIsAdmin(data.isAdmin);
        setHasNoJobGroups(data.hasNoJobGroups);
      } catch (error) {
        console.error('Error fetching applications:', error);
        navigate('/login');
      }
    };

    fetchApplications();
    const getTimezoneAbbreviation = () => {
      const date = new Date();
      const timeString = date.toLocaleTimeString('en-us', {
        timeZoneName: 'short',
      });
      const abbreviationMatch = timeString.split(' ').pop();
      setTimezoneAbbreviation(abbreviationMatch || '');
    };

    getTimezoneAbbreviation();
  }, [
    jobId,
    jobGroupId,
    navigate,
    searchText,
    resultFilter,
    sortColumn,
    sortOrder,
    currentPage,
  ]);

  // Debounce function to throttle updates to `setSearchText`
  const updateSearchText = debounce((value) => {
    if (value.length > 1) {
      setSearchText(value);
    } else {
      setSearchText(''); // Reset if fewer than 2 characters
    }
  }, 300); // Adjust debounce delay as needed

  useEffect(() => {
    updateSearchText(searchInput);
    return () => {
      updateSearchText.cancel();
    };
  }, [searchInput, updateSearchText]);

  const handlePageChange = useCallback((direction) => {
    setCurrentPage((prevPage) =>
      direction === 'next' ? prevPage + 1 : Math.max(prevPage - 1, 1)
    );
  }, []);

  const renderPagination = () => (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <Button
        variant="secondary"
        size="sm"
        disabled={currentPage === 1}
        onClick={() => handlePageChange('prev')}
      >
        Previous
      </Button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        variant="secondary"
        size="sm"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange('next')}
      >
        Next
      </Button>
    </div>
  );

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      sortColumn === column ? (prevOrder === 'ASC' ? 'DESC' : 'ASC') : 'DESC'
    );
    setSortColumn(column);
    setCurrentPage(1); // Reset to first page when sorting changes
  };
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const formatDuration = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, '0')}`;
  };

  const handleBadgeClick = (result, application) => {
    // Directly navigate to sessionUrl for FAILED, INCOMPLETE, and PASSED
    if (
      ['FAILED', 'INCOMPLETE', 'PASSED'].includes(result) &&
      application.sessionUrl
    ) {
      window.open(application.sessionUrl, '_blank', 'noopener,noreferrer');
      return;
    }

    if (result === 'ENGAGED') {
      return; // Don't show a modal for ENGAGED
    }

    let title = '';
    let content = '';

    switch (result) {
      case 'KNOCKOUT_FAILED':
        title = 'Knockouts';
        content = renderFailedKnockouts(application.failedKnockouts);
        break;
      case null:
        title = 'Engaged Details';
        content = renderInterviewNowLinkDetails(
          application.firstVisitedLinkAt,
          application.interviewNowPreviewUrl,
          application.interviewNowLiveUrl
        );
        break;
      default:
        return;
    }

    setModalTitle(title);
    setModalContent(content);
    setShowModal(true);
  };

  const renderInterviewNowLinkDetails = (
    firstVisitedLinkAt,
    interviewNowPreviewUrl,
    interviewNowLiveUrl
  ) => {
    const interviewNowUrl = isAdmin
      ? interviewNowLiveUrl
      : interviewNowPreviewUrl;

    return (
      <div>
        {/* First Visited Link At */}
        <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
          First Visited Link At:
        </div>
        <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
          {firstVisitedLinkAt
            ? `${formatDateTime(firstVisitedLinkAt)} (${timezoneAbbreviation})`
            : 'Never'}
        </div>

        {/* Interview Now Link */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            textAlign: 'left',
            marginBottom: '5px',
          }}
        >
          <span>Interview Now Link:</span>
          <Copy
            style={{ fontSize: '1.2rem', color: '#007bff', cursor: 'pointer' }}
            onClick={() => handleCopy(interviewNowUrl)}
          />
        </div>
        <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
          {interviewNowUrl ? (
            <Link to={interviewNowUrl}>{interviewNowUrl}</Link>
          ) : (
            'Not Available'
          )}
        </div>
      </div>
    );
  };

  const renderFailedKnockouts = (failedKnockouts) => {
    if (!failedKnockouts?.length) {
      return 'No failed knockouts provided.';
    }

    return (
      <div>
        {failedKnockouts.map((knockout, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <div style={{ fontWeight: 'bold', textAlign: 'left' }}>{`Q: ${
              knockout?.question || 'N/A'
            }`}</div>
            <div style={{ marginLeft: '10px' }}>
              Applicant Answer: {knockout?.answer || 'N/A'}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const RenderContactDetails = ({ phone, email }) => {
    return (
      <div>
        {/* Phone */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginBottom: '10px',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
          onClick={() => handleCopy(phone)}
        >
          {phone}
          <Copy style={{ fontSize: '1.2rem', color: '#007bff' }} />
        </div>

        {/* Email */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem',
          }}
          onClick={() => handleCopy(email)}
        >
          {email}
          <Copy style={{ fontSize: '1.2rem', color: '#007bff' }} />
        </div>
      </div>
    );
  };

  const handleCopy = (text) => {
    if (!text) return; // Prevent copying null/undefined values

    navigator.clipboard.writeText(text);
  };

  const handleContactInfoClick = (application) => {
    setModalTitle('Contact Info');
    setModalContent(
      <RenderContactDetails
        phone={application.phone}
        email={application.email}
      />
    );
    setShowModal(true);
  };

  return (
    <Container fluid className="wide-container small-font">
      {!isLoading && applications && (
        <>
          <AppPageTitle
            preTitle={jobId ? 'Applicants for: ' : ''}
            title={jobId ? jobTitle : 'Applicants'}
          />
          <div>
            <Row className="mb-3">
              <Col xs={12} md={4} className="position-relative">
                <Form.Control
                  type="text"
                  placeholder="Search for applicant..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="w-100"
                />
                {searchInput && (
                  <button
                    type="button"
                    onClick={() => setSearchInput('')}
                    className="position-absolute top-50 end-0 translate-middle-y me-3"
                    aria-label="Clear"
                    style={{
                      background: 'none',
                      border: 'none',
                      fontSize: '1.25rem',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '1.5rem',
                      height: '1.5rem',
                      lineHeight: '1',
                    }}
                  >
                    &times;
                  </button>
                )}
              </Col>
              <Col
                xs={12}
                md={8}
                className="d-flex flex-wrap align-items-center"
              >
                {Object.keys(resultFilter).map((key) => (
                  <Form.Check
                    inline
                    key={key}
                    label={key.replace('_', ' ')}
                    name={key}
                    type="checkbox"
                    checked={resultFilter[key]}
                    onChange={(e) => {
                      setResultFilter({
                        ...resultFilter,
                        [e.target.name]: e.target.checked,
                      });
                      setCurrentPage(1);
                    }}
                    className="me-2"
                  />
                ))}
              </Col>
            </Row>

            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="sticky-header"
            >
              <thead>
                <tr>
                  <th
                    onClick={() => handleSort('createdAt')}
                    style={{ cursor: 'pointer' }}
                  >
                    Created At ({timezoneAbbreviation}){' '}
                    {sortColumn === 'createdAt'
                      ? sortOrder === 'ASC'
                        ? '↑'
                        : '↓'
                      : '↕'}
                  </th>
                  <th className="job-sessions-page-td-width">Applicant</th>
                  <th className="job-sessions-page-td-width">Team</th>
                  <th className="job-sessions-page-td-width">Job</th>
                  <th className="text-center">Result</th>
                  <th className="text-center">Duration</th>
                  <th className="text-center">SMS</th>
                  {/*                 <th
                  onClick={() => handleSort('firstVisitedLinkAt')}
                  style={{ cursor: 'pointer' }}
                >
                  Link Viewed At ({timezoneAbbreviation}){' '}
                  {sortColumn === 'firstVisitedLinkAt'
                    ? sortOrder === 'ASC'
                      ? '↑'
                      : '↓'
                    : '↕'}
                </th> */}
                  <th className="text-center">Manage</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application, index) => (
                  <tr key={index}>
                    <td>{formatDateTime(application.createdAt)}</td>
                    <td
                      className="job-sessions-page-td-width"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleContactInfoClick(application); // Pass `true` to indicate contact info
                      }}
                    >
                      <PersonRolodex
                        style={{
                          color: 'inherit',
                          fontSize: '1rem',
                          marginRight: '5px',
                        }}
                      />{' '}
                      {`${application.firstName} ${application.lastName}`}
                    </td>
                    <td className="job-sessions-page-td-width">
                      {application.jobGroupName}
                    </td>
                    <td className="job-sessions-page-td-width">
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                        to={`/jobs/${application.internalJobId}/edit`}
                      >
                        {application.jobTitle}
                      </Link>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        cursor:
                          application.result === 'IN_PROGRESS'
                            ? 'default' // No cursor for IN_PROGRESS
                            : 'pointer', // Show cursor for all other cases, including null
                      }}
                    >
                      {['FAILED', 'INCOMPLETE', 'PASSED'].includes(
                        application.result
                      ) ? (
                        <Link
                          to={application.sessionUrl} // Uses React Router Link
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <Badge
                            bg={
                              application.result === 'FAILED'
                                ? 'danger'
                                : application.result === 'INCOMPLETE'
                                ? 'secondary'
                                : application.result === 'PASSED'
                                ? 'success'
                                : 'light'
                            }
                            style={{ color: 'white' }} // Keeps white text for contrast
                          >
                            {application.result.replace('_', ' ')}
                          </Badge>
                        </Link>
                      ) : (
                        <Badge
                          bg={
                            application.result === 'KNOCKOUT_FAILED'
                              ? 'warning'
                              : application.result === 'IN_PROGRESS'
                              ? 'primary'
                              : '#b0cfff'
                          }
                          style={{
                            backgroundColor:
                              application.result === 'KNOCKOUT_FAILED'
                                ? undefined
                                : application.result === 'IN_PROGRESS'
                                ? undefined
                                : '#b0cfff', // Directly set the custom color when needed
                            color: application.result ? 'white' : 'black', // Ensures black text for light backgrounds
                          }}
                          onClick={() =>
                            handleBadgeClick(application.result, application)
                          }
                        >
                          {application.result
                            ? application.result.replace('_', ' ')
                            : 'ENGAGED'}
                        </Badge>
                      )}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => {
                        if (application.recordingUrl) {
                          window.open(
                            application.recordingUrl,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        }
                      }}
                      style={{
                        cursor: application.recordingUrl
                          ? 'pointer'
                          : 'default',
                      }}
                    >
                      {application.duration ? (
                        formatDuration(application.duration)
                      ) : (
                        <span></span>
                      )}
                    </td>
                    <td
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center', // Centers the content horizontally
                        verticalAlign: 'middle', // Ensures content is vertically centered
                      }}
                      onClick={() => {
                        if (application.messages?.conversationId) {
                          navigate(
                            `/conversation/messages/${application.messages?.conversationId}?applicationId=${application.id}`
                          );
                        }
                      }}
                    >
                      {application.messages?.conversationId && (
                        <Link
                          to={`/conversation/messages/${application.messages?.conversationId}?applicationId=${application.id}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center', // Centers content vertically
                            gap: '5px', // Adds spacing between the icon and the number
                            color: 'inherit',
                            textDecoration: 'none',
                            width: '100%',
                            height: '100%',
                          }}
                          onClick={(e) => e.stopPropagation()} // Prevents double navigation
                        >
                          <ChatLeftDotsFill
                            style={{
                              color:
                                application.messages.countUnreadMessages > 0
                                  ? '#fd7e14'
                                  : 'inherit',
                            }}
                          />
                          {application.messages?.countUnreadMessages > 0 &&
                            application.messages?.countUnreadMessages}
                        </Link>
                      )}
                    </td>

                    {/*                   <td>
                    {application.firstVisitedLinkAt &&
                      formatDateTime(application.firstVisitedLinkAt)}
                  </td> */}
                    <td
                      style={{
                        cursor: application.manageUrl ? 'pointer' : 'default',
                        textAlign: 'center', // Centers content horizontally
                        verticalAlign: 'middle', // Ensures content is vertically centered
                      }}
                    >
                      {application.manageUrl ? (
                        <Link
                          to={application.manageUrl}
                          style={{
                            display: 'flex',
                            alignItems: 'center', // Centers content vertically
                            justifyContent: 'center',
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <PencilSquare />
                        </Link>
                      ) : (
                        <span>&nbsp;</span> // Ensures the cell isn't missing in the table structure
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalContent}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            {renderPagination()}
          </div>
        </>
      )}
      {!isLoading && applications && applications.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-center mt-4">👥 No Applicants to see.</h3>
          {hasNoJobGroups && (
            <h4 className="mt-4">
              Make sure you’re part of a Team with enabled and published Jobs.
            </h4>
          )}
        </div>
      )}
    </Container>
  );
};

export default CompanyJobApplicationsPage;
