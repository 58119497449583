import React, { useState } from 'react';
import './FAQSection.css';

const faqData = [
  {
    question: 'What if I already have a sales team?',
    answer: `That's terrific! Our AI phone screening is designed to be your sales team's assistant. It handles time-consuming qualification calls and basic screening, eliminating phone tag and allowing your team to focus on talking to the most qualified leads. While our AI streamlines lead qualification, we still recommend having skilled salespeople guide prospects through the final stages.`,
  },
  {
    question: `How does your AI phone screener compare with a human screener?`,
    answer: `Our focus is optimizing the aspects of lead qualification that can be automated, ensuring human interactions remain meaningful. In a world of increasing lead volume, the top of the sales funnel has never been more overwhelming for sales teams. Our mission is to connect qualified leads with your sales team faster by screening prospects nearly instantaneously.`,
  },
  {
    question: `Do prospects know they're speaking with an AI phone screener?`,
    answer: `Yes, our AI transparently informs prospects at the beginning of the call that they are speaking with an AI assistant. Despite this, over 95% of calls proceed smoothly to completion, demonstrating that prospects are comfortable with the AI-driven process.`,
  },
  {
    question: 'Can I customize the voice of my AI phone screener?',
    answer: `Yes, you can train the AI to clone the voice of anyone on your team, creating a personalized experience that makes it sound as though the prospect is speaking directly with you.`,
  },
  {
    question: 'Do you offer a free trial?',
    answer: `Yes, we provide a free trial that includes up to 10 phone screenings. This allows you to experience firsthand the quality of our AI phone screener and see how it can enhance your lead qualification process.`,
  },
  {
    question: 'What if the prospect hangs up during the middle of the call?',
    answer: `If a prospect hangs up, the AI will immediately call them back to continue the screening where it left off. If they don't answer, the AI will leave a voicemail with instructions on how to restart the screening at their convenience.`,
  },
  {
    question:
      'What if a prospect is unavailable or wants to schedule for a specific time?',
    answer: `Our AI SMS and Email auto-responder informs prospects that they can start their qualification call at any time that works for them. Clear instructions are provided, including a link to begin when they're ready.`,
  },
  {
    question: 'Can I use the system only during off-hours and weekends?',
    answer: `Yes, you can customize engagement hours in your Account Dashboard. However, if a prospect responds to an SMS or Email outside the designated hours, they can still proceed with the screening—provided your account has available credits.`,
  },
  {
    question: 'How does the system integrate with my CRM?',
    answer: `We set up two custom automations tailored to your CRM workflow:
 Lead Capture Automation: Retrieves new leads who haven't been contacted.
 Status Update Automation: Updates lead records with key details, including:
 - Status changes to "Contacted" or any custom status in your CRM
 - Outcomes of the screening call, such as "Unqualified," "Needs Follow-up," or "Sales-Ready"
 These automations seamlessly integrate with your existing processes while saving time.`,
  },
  {
    question: 'What CRM platforms do you support?',
    answer: `We can automate with all major CRM platforms.`,
  },
  {
    question: 'Does your system require API keys for my CRM?',
    answer: `No, we don't require API keys or special permissions. Instead, we build custom automations that mimic the actions of a sales rep logging into your CRM to retrieve and update lead data. All we need is a standard user account login—the same type you'd create for a sales rep.`,
  },
  {
    question: 'How many times do you contact leads?',
    answer: `We automatically contact leads twice: first, immediately after they show interest, and then again 24 and 36 hours later if they haven't completed their screening call.`,
  },
  {
    question: 'How many questions can I ask prospects during the screening?',
    answer: `There is no default limit to the number of questions your AI screener can ask during a phone call.`,
  },
  {
    question: 'How many qualification questions can I have?',
    answer: `You can include as many qualification questions as needed. However, to optimize completion rates, we recommend keeping the number to 7 or fewer, as too many questions can discourage prospects from completing the process.`,
  },
  {
    question: 'How do you determine whether a lead is qualified?',
    answer: `You can define and manage the Qualification Criteria in your account dashboard to determine if a lead is ready for sales contact. This allows you to tailor the evaluation process to your specific needs.`,
  },
  {
    question: 'Are the screening calls recorded?',
    answer: `Yes, all calls are recorded and can be accessed in your Account dashboard.`,
  },
  {
    question: 'What reporting or metrics do you provide?',
    answer: `We provide comprehensive reports including:
 - Exportable reports of all screening activity
 - Conversion rates by campaign
 - Speed to qualification
 - Call outcome analysis`,
  },
  {
    question:
      'Can I customize the SMS and Email messages sent to prospects after screening?',
    answer: `Yes, you can customize all message content in your account dashboard.`,
  },
  {
    question: 'Can I be notified when a lead is qualified?',
    answer: `Yes, you can enable email notifications in your Account Dashboard.`,
  },
  {
    question:
      'How quickly are prospects notified of their qualification status?',
    answer: `Prospects are notified via SMS and Email within 15 minutes of completing their screening call, providing clear communication on next steps.`,
  },
];

const FAQSection = () => {
  const [visibleFaqs, setVisibleFaqs] = useState(5);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const viewAllFaqs = () => {
    setVisibleFaqs(faqData.length);
  };

  return (
    <div className="faq-section">
      <div className="faq-container">
        {/* Sidebar on Desktop */}
        <div className="faq-sidebar">
          <h2>FAQ</h2>
        </div>

        {/* Accordion List */}
        <div className="faq-accordion">
          {faqData.slice(0, visibleFaqs).map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span className="faq-toggle">
                  {expandedIndex === index ? '-' : '+'}
                </span>
              </div>
              {expandedIndex === index && (
                <div
                  className="faq-answer"
                  dangerouslySetInnerHTML={{
                    __html: faq.answer.replace(/\n/g, '<br />'),
                  }}
                />
              )}
            </div>
          ))}
          <div className="view-all-btn-container">
            {visibleFaqs < faqData.length && (
              <button className="view-all-btn" onClick={viewAllFaqs}>
                View All
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
