import React, { useEffect } from 'react';

const TermsOfService = () => {
  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">
        INSTAHEAR LLC
        <br />
        Terms of Service
      </h1>
      <p className="text-center">
        <strong>Effective Date:</strong> 10/17/2024
      </p>
      <div className="terms-content">
        <section className="mb-4">
          <h2>1. Introduction</h2>
          <p>
            Welcome to InstaHear LLC (“Company”, “we”, “our”, “us”)! These Terms
            and Conditions (“Terms”, “Terms and Conditions”) govern your use of
            our website located at{' '}
            <a href="https://instahear.ai">https://instahear.ai</a> (together or
            individually “Service”) operated by InstaHear LLC.
          </p>
          <p>
            Your agreement to these Terms is confirmed by your access and use of
            the Service. By accessing or using the Service, you agree to be
            bound by these Terms. If you disagree with any part of the terms,
            then you do not have permission to access the Service.
          </p>
        </section>

        <section className="mb-4">
          <h2>2. Communications</h2>
          <p>
            By creating an account on our Service, you agree to subscribe to
            newsletters, marketing or promotional materials, and other
            information we may send. However, you may opt out of receiving any
            or all of these communications by following the unsubscribe link or
            by emailing us at{' '}
            <a href="mailto:terms-of-service@instahear.ai">
              terms-of-service@instahear.ai
            </a>
            .
          </p>
        </section>

        <section className="mb-4">
          <h2>3. Purchases</h2>
          <p>
            If you wish to purchase any product or service made available
            through the Service (“Purchase”), you may be asked to supply certain
            information relevant to your Purchase, including, but not limited
            to, your credit card number, the expiration date of your credit
            card, your billing address, and your shipping information.
          </p>
          <p>
            You represent and warrant that: (i) you have the legal right to use
            any credit card(s) or other payment method(s) in connection with any
            Purchase, and (ii) the information you provide to us is accurate,
            true, and complete.
          </p>
        </section>

        <section className="mb-4">
          <h2>4. Contests, Sweepstakes, and Promotions</h2>
          <p>
            Any contests, sweepstakes, or other promotions (collectively,
            “Promotions”) made available through the Service may be governed by
            separate rules. If you participate in any Promotions, please review
            the applicable rules and our Privacy Policy. If the rules for a
            Promotion conflict with these Terms and Conditions, the Promotion
            rules will apply.
          </p>
        </section>

        <section className="mb-4">
          <h2>5. Content</h2>
          <p>
            Our Service allows you to post, link, store, share, and otherwise
            make available certain information, text, graphics, videos, or other
            material (“Content”). You are responsible for the Content you post,
            including its legality, reliability, and appropriateness.
          </p>
          <p>
            By posting Content, you represent and warrant that: (i) the Content
            is yours (you own it) or you have the right to use it, and (ii)
            posting your Content does not violate any rights, including but not
            limited to privacy, publicity, copyrights, trademarks, or any other
            rights of any person or entity.
          </p>
        </section>

        <section className="mb-4">
          <h2>6. Accounts</h2>
          <p>
            When you create an account with us, you guarantee that you are at
            least 18 years old and that the information you provide is accurate,
            complete, and current. Providing inaccurate or incomplete
            information may result in immediate termination of your account.
          </p>
        </section>

        <section className="mb-4">
          <h2>7. Intellectual Property</h2>
          <p>
            The Service and all original content (excluding Content provided by
            users), features, and functionality are and will remain the
            exclusive property of InstaHear LLC and its licensors.
          </p>
        </section>

        <section className="mb-4">
          <h2>8. Links to Other Websites</h2>
          <p>
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by InstaHear LLC. We have no
            control over, and assume no responsibility for, the content, privacy
            policies, or practices of any third-party websites.
          </p>
        </section>

        <section className="mb-4">
          <h2>9. Disclaimer of Warranty</h2>
          <p>
            The Service is provided on an “as is” and “as available” basis.
            InstaHear LLC makes no warranties, whether express or implied,
            regarding the operation of the Service or the content available
            therein. Your use of the Service is at your sole risk.
          </p>
        </section>

        <section className="mb-4">
          <h2>10. Limitation of Liability</h2>
          <p>
            In no event shall InstaHear LLC, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including but not limited to loss of profits, data, or use,
            resulting from your access to or use of the Service. The total
            liability for any claims under these Terms shall not exceed the
            amount you have paid to us or $100, whichever is higher.
          </p>
        </section>

        <section className="mb-4">
          <h2>11. Termination</h2>
          <p>
            We may terminate or suspend your account immediately, without
            notice, if you violate these Terms. Upon termination, your right to
            use the Service will cease immediately.
          </p>
        </section>

        <section className="mb-4">
          <h2>12. Governing Law</h2>
          <p>
            These Terms are governed by the laws of the state of Idaho, without
            regard to its conflict of law principles.
          </p>
        </section>

        <section className="mb-4">
          <h2>13. Changes to Service</h2>
          <p>
            We reserve the right to modify or discontinue the Service, or any
            part of it, at our sole discretion without prior notice. We will not
            be liable for any unavailability of the Service.
          </p>
        </section>

        <section className="mb-4">
          <h2>14. Amendments to Terms</h2>
          <p>
            We may amend these Terms at any time by posting the updated Terms on
            this site. Your continued use of the Service after any changes
            indicates your acceptance of the new Terms.
          </p>
        </section>

        <section className="mb-4">
          <h2>15. Acknowledgement</h2>
          <p>
            By using the Service, you acknowledge that you have read and agree
            to these Terms and Conditions.
          </p>
        </section>

        <section className="mb-4">
          <h2>16. Contact Us</h2>
          <p>For questions, feedback, or support, please contact us:</p>
          <ul>
            <li>
              By email:{' '}
              <a href="mailto:terms-of-service@instahear.ai">
                terms-of-service@instahear.ai
              </a>
            </li>
            <li>
              By mail: Address: 784 S CLEARWATER LOOP STE R POST FALLS, ID 83854
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2>17. Effective Date</h2>
          <p>These Terms are effective as of October 17, 2024.</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
