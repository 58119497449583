import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Button, Form } from 'react-bootstrap';
import { PeopleFill, PencilSquare } from 'react-bootstrap-icons';
import AppPageTitle from '../components/app/AppPageTitle';
import './CompanyAssessmentsPage.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const CompanyTeamsPage = () => {
  const navigate = useNavigate();
  const [jobGroups, setJobGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCreatePermission, setHasCreatePermission] = useState(false);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortColumn, setSortColumn] = useState('createdAt');
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchJobGroups = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/job-groups`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch teams');
        }

        const data = await response.json();
        setJobGroups(data.jobGroups);
        setHasCreatePermission(data.hasCreatePermission);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchJobGroups();
  }, []);

  useEffect(() => {
    const date = new Date();
    const timeString = date.toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    });
    setTimezoneAbbreviation(timeString.split(' ')[2] || '');
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const filteredAndSortedJobGroups = [...jobGroups]
    .filter((jg) => {
      return (
        jg.internalName.toLowerCase().includes(searchQuery) ||
        jg.internalId.toLowerCase().includes(searchQuery) ||
        jg.publicName.toLowerCase().includes(searchQuery)
      );
    })
    .sort((a, b) => {
      if (sortColumn === 'createdAt') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      setSortColumn(column);
      setSortOrder('DESC');
    }
  };

  return (
    <div className="wide-container small-font">
      {!isLoading && jobGroups && (
        <>
          <div>
            <AppPageTitle title="Teams" />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1 }}>
                <Form.Control
                  type="text"
                  placeholder="Search teams..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    width: '300px',
                    display: 'inline-block',
                    marginRight: '15px',
                  }}
                />
              </div>
              <Button
                variant="primary"
                size="sm"
                className="fw-bold"
                onClick={() => navigate('/teams/new')}
                disabled={!hasCreatePermission}
              >
                Create Team
              </Button>
            </div>
          </div>
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th
                  onClick={() => handleSort('createdAt')}
                  style={{ cursor: 'pointer' }}
                >
                  Created At ({timezoneAbbreviation}){' '}
                  {sortColumn === 'createdAt' &&
                    (sortOrder === 'ASC' ? '↑' : '↓')}
                </th>
                <th className="company-assessments-page-td-width">
                  Internal Name
                </th>
                <th className="company-assessments-page-td-width">
                  Internal Id
                </th>
                <th className="company-assessments-page-td-width">
                  External Name
                </th>
                <th className="company-assessments-page-td-width">
                  # of Members
                </th>
                <th className="text-center">Manage</th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedJobGroups.map((jg) => (
                <tr key={`${jg.id}`}>
                  <td>{formatDate(jg.createdAt)}</td>
                  <td>{jg.internalName}</td>
                  <td>{jg.internalId}</td>
                  <td>{jg.publicName}</td>
                  <td>
                    <Link
                      to={`/teams/${jg.id}/edit?tab=team-members`}
                      style={{
                        display: 'flex',
                        alignItems: 'center', // Centers content vertically
                        textDecoration: 'none',
                        color: 'inherit',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer', // Ensure pointer cursor when enabled
                      }}
                    >
                      {jg.memberCount}
                      <PeopleFill
                        style={{ marginLeft: '10px', fontSize: '1rem' }}
                      />
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/teams/${jg.id}/edit`}
                      style={{
                        display: 'flex',
                        alignItems: 'center', // Centers content vertically
                        justifyContent: 'center',
                        textDecoration: 'none',
                        color: 'inherit',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer', // Ensure pointer cursor when enabled
                      }}
                    >
                      <PencilSquare />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {!isLoading && jobGroups && jobGroups?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-center mt-4">🚀 No Teams to see.</h3>

          {!hasCreatePermission && (
            <h4 className="mt-4">
              Ask your Team Admin or Company Admin to invite you to a Team.
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyTeamsPage;
