import { Accordion, Card, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import RequiredAsterisk from '../../appPages/RequiredAsterisk';
import InfoTooltip from '../../appPages/InfoTooltip';

const InterviewQuestionsAccordion = ({
  assessment,
  handleFieldChange,
  moveItem,
  deleteItem,
  questionTopicNames,
  openIndexes,
  setOpenIndexes,
  openIndex,
  setOpenIndex,
}) => {
  const toggleAccordion = (index) => {
    if (openIndexes.length > 0) {
      const newIndexes = openIndexes.includes(index.toString())
        ? openIndexes.filter((i) => i !== index.toString())
        : [...openIndexes, index.toString()];
      setOpenIndexes(newIndexes);
    } else {
      const newIndex = openIndex === index ? null : index;
      setOpenIndex(newIndex);
    }
  };

  return (
    <Accordion
      activeKey={
        openIndexes.length > 0
          ? openIndexes
          : openIndex !== null
          ? openIndex.toString()
          : null
      }
    >
      {assessment.questions.map((item, index) => (
        <Card key={index} className="mb-3">
          <Accordion.Item eventKey={index.toString()}>
            <div className="d-flex align-items-center p-3 bg-white">
              <Col sm={3} className="small-font">
                <strong>Question {index + 1}</strong>
                <RequiredAsterisk />
              </Col>
              <Col sm={8}>
                <Form.Control
                  as="textarea"
                  value={item.question}
                  rows={2}
                  onChange={(e) => {
                    const updatedQuestions = [...assessment.questions];
                    updatedQuestions[index].question = e.target.value;
                    handleFieldChange('questions', updatedQuestions);
                  }}
                  className="small-font"
                />
              </Col>
              <Col sm={1} className="d-flex justify-content-center">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="btn btn-link p-0"
                  style={{
                    transform:
                      openIndexes.includes(index.toString()) ||
                      openIndex === index
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </button>
              </Col>
            </div>

            <Accordion.Body>
              {/* Clarifying Questions */}
              <Form.Group as={Row} className="mb-2 small-font">
                <Form.Label column sm={3} className="small-font">
                  Clarifying Questions
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={item.clarifications}
                    onChange={(e) => {
                      const updatedQuestions = [...assessment.questions];
                      updatedQuestions[index].clarifications = e.target.value;
                      handleFieldChange('questions', updatedQuestions);
                    }}
                    className="small-font"
                  />
                </Col>
              </Form.Group>

              {/* Data Topic */}
              <Form.Group as={Row} className="mb-3 small-font">
                <Form.Label column sm={3} className="small-font">
                  Data Topic
                  <RequiredAsterisk />
                  <InfoTooltip message="Required for AI to summarize interview and categorize responses." />
                </Form.Label>
                <Col sm={9}>
                  <Select
                    options={questionTopicNames}
                    value={
                      item.questionTopicName
                        ? {
                            value: item.questionTopicName,
                            label: item.questionTopicName,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const updatedQuestions = [...assessment.questions];
                      updatedQuestions[index].questionTopicName = selectedOption
                        ? selectedOption.value
                        : null;
                      handleFieldChange('questions', updatedQuestions);
                    }}
                    placeholder="Select a Topic"
                    isClearable
                    classNamePrefix="react-select"
                    className="small-font"
                  />
                </Col>
              </Form.Group>

              {/* Response Scoring Section */}
              <Form.Group
                as={Row}
                className="mb-3 small-font align-items-center"
              >
                <Form.Label
                  column
                  sm={3}
                  className="small-font d-flex align-items-center"
                >
                  Response Scoring
                </Form.Label>
                <Col sm={9} className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    id={`response-scoring-none-${index}`}
                    label="None"
                    name={`response-scoring-${index}`}
                    checked={item.responseScoringType === 'none'}
                    onChange={() => {
                      const updatedQuestions = [...assessment.questions];
                      updatedQuestions[index].responseScoringType = 'none';
                      handleFieldChange('questions', updatedQuestions);
                    }}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    id={`response-scoring-list-${index}`}
                    label="List"
                    name={`response-scoring-${index}`}
                    checked={item.responseScoringType === 'list'}
                    onChange={() => {
                      const updatedQuestions = [...assessment.questions];
                      updatedQuestions[index].responseScoringType = 'list';
                      handleFieldChange('questions', updatedQuestions);
                    }}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    id={`response-scoring-advanced-${index}`}
                    label="Advanced"
                    name={`response-scoring-${index}`}
                    checked={item.responseScoringType === 'advanced'}
                    onChange={() => {
                      const updatedQuestions = [...assessment.questions];
                      updatedQuestions[index].responseScoringType = 'advanced';
                      handleFieldChange('questions', updatedQuestions);
                    }}
                  />
                </Col>
              </Form.Group>

              {item.responseScoringType !== 'none' && (
                <>
                  {item.responseScoringType === 'advanced' && (
                    <Form.Group as={Row} className="mb-3 small-font">
                      <Form.Label column sm={3} className="small-font">
                        Evaluation Prompt
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={item.responseEvaluationPrompt || ''}
                          onChange={(e) => {
                            const updatedQuestions = [...assessment.questions];
                            updatedQuestions[index].responseEvaluationPrompt =
                              e.target.value;
                            handleFieldChange('questions', updatedQuestions);
                          }}
                          className="small-font"
                        />
                      </Col>
                    </Form.Group>
                  )}

                  {item.responseScoringType === 'list' && (
                    <Form.Group
                      as={Row}
                      className="mb-2 small-font align-items-center"
                    >
                      {/* List scoring content */}
                      <Row className="align-items-center w-100">
                        <Col
                          sm={3}
                          className="small-font d-flex align-items-center"
                        ></Col>
                        <Col sm={9}>
                          <Row className="align-items-center">
                            <Col sm={7} className="small-font">
                              <strong>Caller Response</strong>
                            </Col>
                            <Col sm={4} className="text-center small-font">
                              <strong>Score</strong>
                            </Col>
                            <Col sm={1}></Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="w-100 mt-3">
                        <Col sm={{ span: 9, offset: 3 }}>
                          {assessment.questions[index].responseScoreItems?.map(
                            (responseItem, i) => (
                              <Col key={i} sm={12} className="mb-2 px-0">
                                <Card className="shadow-sm w-100">
                                  <Card.Body>
                                    <Row className="align-items-center">
                                      <Col sm={7}>
                                        <Form.Control
                                          type="text"
                                          value={responseItem.response}
                                          onChange={(e) => {
                                            const updatedResponseScoreItems = [
                                              ...assessment.questions[index]
                                                .responseScoreItems,
                                            ];
                                            updatedResponseScoreItems[
                                              i
                                            ].response = e.target.value;
                                            const updatedQuestions = [
                                              ...assessment.questions,
                                            ];
                                            updatedQuestions[
                                              index
                                            ].responseScoreItems =
                                              updatedResponseScoreItems;
                                            handleFieldChange(
                                              'questions',
                                              updatedQuestions
                                            );
                                          }}
                                          className="small-font"
                                          placeholder="Enter response..."
                                        />
                                      </Col>
                                      <Col sm={4} className="text-center">
                                        <Form.Select
                                          value={responseItem.score || ''}
                                          onChange={(e) => {
                                            const updatedResponseScoreItems = [
                                              ...assessment.questions[index]
                                                .responseScoreItems,
                                            ];
                                            updatedResponseScoreItems[i].score =
                                              e.target.value;
                                            const updatedQuestions = [
                                              ...assessment.questions,
                                            ];
                                            updatedQuestions[
                                              index
                                            ].responseScoreItems =
                                              updatedResponseScoreItems;
                                            handleFieldChange(
                                              'questions',
                                              updatedQuestions
                                            );
                                          }}
                                          style={{
                                            width: '125px',
                                            display: 'inline-block',
                                          }}
                                        >
                                          <option value="">Select</option>
                                          <option value="1">PASS</option>
                                          <option value="0">FAIL</option>
                                        </Form.Select>
                                      </Col>
                                      <Col sm={1} className="text-center">
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={() => {
                                            const updatedResponseScoreItems =
                                              assessment.questions[
                                                index
                                              ].responseScoreItems.filter(
                                                (_, idx) => idx !== i
                                              );
                                            const updatedQuestions = [
                                              ...assessment.questions,
                                            ];
                                            updatedQuestions[
                                              index
                                            ].responseScoreItems =
                                              updatedResponseScoreItems;
                                            handleFieldChange(
                                              'questions',
                                              updatedQuestions
                                            );
                                          }}
                                        >
                                          ✖
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            )
                          )}

                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => {
                              const updatedResponseScoreItems = [
                                ...(assessment.questions[index]
                                  .responseScoreItems || []),
                                { response: '', score: '' },
                              ];
                              const updatedQuestions = [
                                ...assessment.questions,
                              ];
                              updatedQuestions[index].responseScoreItems =
                                updatedResponseScoreItems;
                              handleFieldChange('questions', updatedQuestions);
                            }}
                          >
                            Add New
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                </>
              )}

              {/* Move Up/Down/Delete Buttons */}
              <div className="d-flex justify-content-end">
                <Button
                  variant="link"
                  size="sm"
                  disabled={index === 0}
                  onClick={() =>
                    handleFieldChange(
                      'questions',
                      moveItem(assessment.questions, index, -1)
                    )
                  }
                >
                  ↑
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  disabled={index === assessment.questions.length - 1}
                  onClick={() =>
                    handleFieldChange(
                      'questions',
                      moveItem(assessment.questions, index, 1)
                    )
                  }
                >
                  ↓
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() =>
                    handleFieldChange(
                      'questions',
                      deleteItem(assessment.questions, index)
                    )
                  }
                >
                  Delete
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Card>
      ))}
    </Accordion>
  );
};

export default InterviewQuestionsAccordion;
