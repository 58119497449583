import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const EditSessionDetailPage = () => {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isStatusChanged, setIsStatusChanged] = useState(false);

  // Fetch session data function, wrapped in useCallback to ensure stable reference
  const fetchSessionData = useCallback(async () => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/sessions/${sessionId}/view`,
        {
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message || errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        setError(errorData.message || errorData.error);
        return;
      }

      const data = await response.json();
      setSessionData(data);
      setSelectedStatus(data.result);
    } catch (error) {
      toast.error('Failed to fetch session data', {
        position: 'top-right',
        autoClose: 5000,
      });
      setError('Failed to fetch session data');
    } finally {
      setLoading(false);
    }
  }, [sessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setIsStatusChanged(true);
  };

  const handleUpdateStatus = async () => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/sessions/${sessionId}/edit`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            sessionId,
            result: selectedStatus,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message || errorData.error}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      const data = await response.json();
      toast.success(data.message || 'Status updated successfully!', {
        position: 'top-right',
        autoClose: 5000,
      });

      setIsStatusChanged(false);
      fetchSessionData(); // Refresh session data on success
    } catch (error) {
      toast.error('Failed to update status', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center">Error: {error}</div>;
  }

  if (!sessionData) {
    return <div className="text-center">No session data available.</div>;
  }

  return (
    <div className="container my-5">
      <ToastContainer />
      <div className="form-container bg-white px-4 rounded shadow my-3">
        <div className="card-body">
          <h5
            className="card-title mb-4 text-center"
            style={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
          >
            Update Interview Result
          </h5>
          <p>
            <strong>Name:</strong> {sessionData.firstName}{' '}
            {sessionData.lastName}
          </p>
          <div className="d-flex justify-content-between">
            <p>
              <strong>Phone:</strong> {sessionData.phone}
            </p>
          </div>
          <p>
            <strong>Job:</strong> {sessionData.jobTitle}
          </p>
          {/* Status Dropdown */}
          <div className="mb-3">
            <strong>Result: </strong>
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="form-select d-inline w-auto ms-2"
            >
              <option value="INCOMPLETE">INCOMPLETE</option>
              <option value="PASSED">PASSED</option>
              <option value="FAILED">FAILED</option>
            </select>
          </div>
        </div>
      </div>

      {isStatusChanged && (
        <div className="text-center my-4">
          <button className="btn btn-danger" onClick={handleUpdateStatus}>
            Update Result
          </button>
        </div>
      )}

      <footer className="text-center mt-5">
        2025 InstaHear LLC • All Rights Reserved
      </footer>
    </div>
  );
};

export default EditSessionDetailPage;
