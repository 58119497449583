import React, { useEffect } from 'react';

const RefundPolicy = () => {
  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">
        INSTAHEAR LLC
        <br />
        Refund Policy
      </h1>
      <p className="text-center">
        <strong>Effective Date:</strong> 10/17/2024
      </p>
      <div className="text-center">
        <section className="mb-4">
          <h2 className="mb-4">30-Day Money Back Guarantee</h2>
          <p>
            We are committed to delivering exceptional service to our customers.
            If you are dissatisfied with our software for any reason within 30
            days of your purchase, you are entitled to a complete refund of your
            payment. To initiate the refund process, simply submit your request
            explaining your concerns to your Account Manager or{' '}
            <a href="mailto:billing@instahear.ai">billing@instahear.ai</a>, and
            we will process your reimbursement promptly. We stand behind our
            100% satisfaction guarantee with no additional conditions.
          </p>
        </section>
      </div>
    </div>
  );
};

export default RefundPolicy;
