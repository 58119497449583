import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WhyInstaHearPage.css';
import FeatureBenefitsSection from '../components/sections/FeatureBenefitsSection';
import HowItWorksDemoGrid from '../components/homepage-how-it-works/HowItWorksDemoGrid';
import BannerSection from '../components/sections/BannerSection';
import LogoSection from '../components/sections/LogoSection';
import HeroSection from '../components/sections/HeroSection';
import { logos } from '../components/logos/atsLogos';

const WhyLeadQualPage = () => {
  const navigate = useNavigate();
  const features = [
    {
      emphasis: 'On-Demand',
      content: 'Capture hot leads 24/7 at the peak of their interest.',
    },
    {
      emphasis: 'Speed Up Conversion',
      content: 'Convert prospects 2x faster and meet sales targets.',
    },
    {
      emphasis: 'Stay Consistent',
      content:
        'Ensure a seamless brand experience where no lead falls through the cracks.',
    },
    {
      emphasis: 'Designed for Humans',
      content:
        "Our conversation experience is designed to feel so human, prospects won't realize they're speaking with AI.",
    },
    {
      emphasis: `Don't Fall Behind`,
      content:
        'Without AI, you risk outdated lead qualification and losing deals to competitors.',
    },
  ];

  const futureProofFeatures = [
    {
      emphasis: 'Less repetition, more sales team satisfaction',
      content: `Sales teams are happier when they don't have to repeat themselves. Deliver one cohesive qualification experience across all channels.`,
    },
    {
      emphasis: '24/7 Lead engagement, exactly when and where you need it',
      content:
        'Answer common questions from prospects immediately within SMS, Email and during the AI conversation.',
    },
    {
      emphasis: 'Instant qualification outcomes logged automatically',
      content:
        'Our platform integrates with 100+ CRM systems, so all conversation activity gets logged in real-time.',
    },
    {
      emphasis: 'Less jumping between platforms',
      content:
        'Your team continues to work directly within your CRM, only using our platform to adjust qualification criteria.',
    },
  ];

  return (
    <div>
      <div className="homepage-container">
        <HeroSection
          id="why-leadqual-hero-section"
          background="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          color="#fff"
          title="Engage Leads Faster\nThan Your Competitors"
          mobileTitle="Engage Leads Faster Than Your\nCompetitors"
          subtitle="Capture qualified prospects at peak interest\nwith 24/7 on-demand AI phone screening."
          mobileSubtitle="Capture qualified prospects at peak interest with\n24/7 on-demand AI phone screening."
        />

        {/* Can You Relate To This? */}
        <section className="why-instahear-section d-flex align-items-center instahear-white-bg">
          <div className="container desktop-container">
            <div className="row">
              <h3 className="why-instahear-section-h3">Can you relate?</h3>
            </div>
            <div className="row experiences-carousel">
              {/* First Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image1.png"
                  alt="We need to hire a ton of people ASAP"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Sales targets keep going up and we need to{' '}
                    <b>qualify more leads ASAP</b>.
                  </p>
                </div>
              </div>

              {/* Second Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image2.png"
                  alt="Our team is burned out from playing phonetag with applicants"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Our team is <b>burned out</b> from{' '}
                    <b>chasing unqualified leads</b>.
                  </p>
                </div>
              </div>

              {/* Third Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image3.png"
                  alt="Applicants never pick the phone when I call them for an interview"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Prospects go cold because we{' '}
                    <b>can't respond fast enough</b>.
                  </p>
                </div>
              </div>

              {/* Fourth Column */}
              <div className="col experiences-carousel-item">
                <img
                  className="experiences-carousel-avatar"
                  src="https://storage.googleapis.com/instahear-react-images/why-instahear-section-images/can-you-relate_image4.png"
                  alt="There aren't enough hours to review the daily truck load of new applicants"
                />
                <div className="experiences-carousel-content">
                  <p>
                    Too many leads, not enough time to{' '}
                    <b>properly qualify them all</b>.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-4 why-instahear-button-container">
              <button
                className="btn btn-ig-purple cta-btn"
                onClick={() => navigate('/demo-signup')}
              >
                Get a Demo
              </button>
            </div>
          </div>
        </section>

        <FeatureBenefitsSection
          id="why-leadqual-features"
          title="Meet Your AI Call Assistant"
          subtitle="Our AI-powered phone screener engages leads within minutes—even at 2 a.m.—so your team can focus on closing deals."
          carouselItems={[
            {
              number: '89',
              metric: '%',
              textLine1: 'of phone calls completed',
              textLine2: 'Same Day',
              textLine3: 'the lead was engaged',
            },
            {
              number: '70',
              metric: '%',
              textLine1: 'of phone calls completed',
              textLine2: '30 Minutes',
              textLine3: 'after lead was engaged',
            },
            {
              number: '49',
              metric: '%',
              textLine1: 'of phone calls completed',
              textLine2: 'Outside of Business Hours',
              textLine3: 'Monday to Friday 9am-5pm',
            },
          ]}
          features={features}
          backgroundColor={'#000'}
          color={'#fff'}
        />

        {/* How It Works Demo Grid */}
        <HowItWorksDemoGrid />

        <LogoSection
          id="integration-section"
          backgroundColor="#00b3a9"
          color="white"
          title="INTEGRATES WITH 100+ CRM PLATFORMS"
          logos={logos}
        />

        <FeatureBenefitsSection
          id="future-proof-features"
          title="Future-proof your sales with AI"
          subtitle="What if your team could capture qualified leads at peak interest and convert 2x as fast? Here's how we supercharge lead qualification:"
          carouselItems={[
            {
              number: '20',
              metric: ' hours',
              textLine1: 'saved Each Week using our',
              textLine2: 'instant SMS engagement',
              textLine3: 'to contact new leads.',
            },
            {
              number: '5',
              metric: ' hours',
              textLine1: 'saved Each Week using our',
              textLine2: 'SMS auto-replier',
              textLine3: 'to handle prospect questions.',
            },
            {
              number: '25',
              metric: ' hours',
              textLine1: 'saved Each Week using our',
              textLine2: 'CRM automations',
              textLine3: 'to log conversation outcomes.',
            },
          ]}
          features={futureProofFeatures}
          backgroundColor={'#000'}
          color={'#fff'}
        />

        <BannerSection
          id="ready-to-2x-conversion"
          backgroundColor="#eef"
          color="black"
          title="Convert 10x more leads automatically while your team focuses on closing"
          subtitle="Qualify leads instantly and win deals ahead of your competitors."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-purple cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default WhyLeadQualPage;
