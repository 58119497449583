import React, { useRef } from 'react';
import './HomePageNavbar.css'; // Ensure this CSS is specific to the Navbar
import { Link } from 'react-router-dom';

const HomePageNavbar = () => {
  const navbarCollapseRef = useRef(null); // Ref for the collapsible navbar

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black fixed-top homepage-navbar">
      <div className="container-fluid desktop-container">
        {/* Brand Logo */}
        <a href="/" className="ig-homepage-logo-link">
          <span className="ig-logo-insta">INSTA</span>
          <span className="ig-logo-hear">HEAR</span>
        </a>

        {/* Navbar Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Collapsible Content */}
        <div
          className="collapse navbar-collapse bg-black w-100"
          id="navbarNav"
          ref={navbarCollapseRef}
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/why-instahear">
                Why InstaHear
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/how-it-works">
                How It Works
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/features">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/login">
                Login
              </a>
            </li>
            <li className="nav-item">
              <Link
                to="/demo-signup"
                className="nav-link btn btn-ig-purple cta-btn ms-lg-3"
              >
                Get a Demo
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default HomePageNavbar;
