import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './HomePage.css'; // Import the custom CSS
import './HomePageNavbar.css'; // Import the custom CSS
import FeatureBenefitsSection from '../components/sections/FeatureBenefitsSection';
import FAQSection from '../components/sections/FAQSection';
import StatsSectionHomepage from '../components/stats/StatsSectionHomepage';
import LogoSection from '../components/sections/LogoSection';
import BannerSection from '../components/sections/BannerSection';
import { logos } from '../components/logos/atsLogos';
import HowItWorksDemoGrid from '../components/homepage-how-it-works/HowItWorksDemoGrid';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const Homepage = () => {
  const features = [
    {
      emphasis: 'Automatic CRM & ATS updates',
      content: 'Contact records updated in real-time.',
    },
    {
      emphasis: 'Instant Engagement',
      content: 'Eliminate phone tag and manual outreach.',
    },
    {
      emphasis: '24/7 Phone Interviews',
      content: 'Screen leads outside business hours.',
    },
    {
      emphasis: 'Customized Qualification Criteria',
      content: 'Prioritize highly qualified leads.',
    },
    {
      emphasis: 'Powerful Reporting',
      content: 'Analytics show where leads are falling off.',
    },
    {
      emphasis: 'Reduce Ad-Spend',
      content: 'Spend less on Google AdWords, etc by converting faster.',
    },
  ];

  return (
    <div>
      <div className="homepage-container">
        {/* Hero Section */}
        <section className="homepage-hero-section d-flex align-items-center">
          <div className="container desktop-container">
            <div className="row">
              {/* Left Column */}
              <div className="col-lg-6 col-md-12 homepage-hero-content">
                <h1 className="hero-title">
                  Life’s too short for calls that don’t pay the bills
                </h1>
                <p className="homepage-hero-subtitle">
                  Our AI phone assistant does more than just answer calls - it
                  qualifies leads and screens talent 24/7. This isn’t your
                  neighborhood bank’s “AI” phone menu - this is next-gen call
                  handling that scales your business
                </p>
                <Link
                  to="/how-it-works"
                  className="hero-cta-link text-decoration-none"
                >
                  <Button className="btn-ig-purple demo-button cta-btn">
                    Try a Test Call
                  </Button>
                </Link>
              </div>
              {/* Right Column */}
              <div className="col-lg-6 col-md-12 homepage-hero-image">
                <img
                  src={
                    'https://storage.googleapis.com/instahear-react-images/hero-section-images/instahear-hero-image-salesman-double-phones.jpg'
                  } // Replace with your actual image URL
                  alt="Salesman who desperately needs InstaHear AI call assistant"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <LogoSection
          id="integration-section"
          backgroundColor="black"
          color="white"
          title="INTEGRATES WITH OVER 100+ PLATFORMS"
          logos={logos}
        />

        {/* How It Works Demo Grid */}
        <HowItWorksDemoGrid
          backgroundColor={
            'radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)'
          }
          fontColor={'#FFF'}
        />

        {/* Stats Section */}
        <StatsSectionHomepage id="stats" />

        {/* Features Section */}
        <FeatureBenefitsSection
          id="features"
          title="Smart call screening that works"
          //featureButtons={featureButtons}
          subtitle={
            'Get more qualified leads by responding faster than your competition.'
          }
          imageSrc={`https://storage.googleapis.com/instahear-react-images/feature-section-images/hr-office-women-shaking-hands.jpg`}
          features={features}
        />

        {/* FAQ Section */}
        <section id="faq" className="faq">
          <FAQSection />
        </section>

        {/* Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="black"
          color="white"
          title="Ready to transform your lead qualification?"
          subtitle="Activate InstaHear on your CRM or ATS to experience the advantages with instant AI phone screens."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-purple cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default Homepage;
