import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown, Container } from 'react-bootstrap';
import {
  FilePersonFill,
  Shuffle,
  Gear,
  Magic,
  QrCode,
} from 'react-bootstrap-icons'; // Correctly importing from react-bootstrap-icons
import AdminChangeCompaniesModal from './AdminChangeCompaniesModal'; // Import your modal component
import './AppNavbar.css';

const PilotNavbar = ({ companyName, userEmail, homeLink, isAdmin }) => {
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);

  const BACKEND_API_URL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:3001'
      : 'https://api.instahear.ai';

  const handleShuffleClick = async () => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/admin/companies`, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setCompanies(data.companies || []);
        setShowModal(true);
      } else {
        console.error('Failed to fetch companies');
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  return (
    <>
      <Navbar
        bg="black"
        variant="dark"
        expand="lg"
        className="mb-4 pilotNavbar"
      >
        <Container fluid>
          <Navbar.Brand
            as={Link}
            to={homeLink}
            className="d-flex align-items-center"
          >
            {/* Brand Logo */}
            <div className="ig-homepage-logo-link">
              <span className="ig-logo-insta">INSTA</span>
              <span className="ig-logo-hear">HEAR</span>
            </div>
          </Navbar.Brand>

          {/* Toggle for the hamburger menu */}
          <Navbar.Toggle aria-controls="navbar-content" />

          <Navbar.Collapse id="navbar-content">
            <Nav className="ms-auto d-flex align-items-center">
              <Nav.Link as={Link} to={'/applications'}>
                Applicants
              </Nav.Link>
              <Nav.Link as={Link} to={'/jobs'}>
                Jobs
              </Nav.Link>
              <Nav.Link as={Link} to={'/call-scripts'}>
                Call Scripts
              </Nav.Link>
              <Nav.Link as={Link} to={'/teams'} className="font-weight-bold">
                Teams
              </Nav.Link>
              <Nav.Link as={Link} to={'/analytics'}>
                Analytics
              </Nav.Link>
              {isAdmin && (
                <Dropdown className="me-4" style={{ marginLeft: '8px' }}>
                  <Dropdown.Toggle
                    as="span"
                    className="d-flex align-items-center"
                    style={{
                      cursor: 'pointer',
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    Admin
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={'/admin/demo'}>
                      <Magic
                        size={20}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        title="Create Demo"
                      />
                      Create Demo
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to={'/admin/qr-code-generator'}>
                      <QrCode
                        size={20}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        title="Company Settings"
                      />
                      QR Codes
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} onClick={handleShuffleClick}>
                      <Shuffle
                        size={20}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        title="Change Companies"
                      />
                      Change Company
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to={'/admin/settings/company'}>
                      <Gear
                        size={20}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                        title="Company Settings"
                      />
                      Company Settings
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Dropdown align="end" style={{ marginLeft: '20px' }}>
                <Dropdown.Toggle
                  as="span"
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer', color: '#fff' }}
                >
                  <div
                    className="d-flex flex-column"
                    style={{ marginRight: '10px', fontSize: '.90rem' }}
                  >
                    <div> {userEmail}</div>
                  </div>
                  <FilePersonFill size={24} className="ml-2" color="#00f9eb" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to={'/logout'}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Modal for changing companies */}
      {isAdmin && (
        <AdminChangeCompaniesModal
          currentCompanyName={companyName}
          companies={companies}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default PilotNavbar;
