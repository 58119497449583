import React, { useState, useEffect, useCallback } from 'react';
import {
  Tabs,
  Tab,
  Spinner,
  Button,
  Card,
  Form,
  Table,
  Container,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { PencilSquare, PersonDash } from 'react-bootstrap-icons';
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './CompanyJobDetailsPage.css';
import AppPageTitle from '../components/app/AppPageTitle';
import RequiredAsterisk from './RequiredAsterisk';
import {
  TeamMemberModal,
  MODAL_TYPES,
} from '../components/app/TeamMemberModal';
import InfoTooltip from './InfoTooltip';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const CompanyTeamEditPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isNewTeam = location.pathname.includes('/teams/new');
  const { jobGroupId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [jobGroup, setJobGroup] = useState(
    isNewTeam
      ? {
          name: '',
          publicName: '',
          alternateId: '',
          userRoles: [],
        }
      : null
  );
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(!isNewTeam);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(MODAL_TYPES.ADD);
  const [selectedMember, setSelectedMember] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [usersNotInJobGroup, setUsersNotInJobGroup] = useState([]);
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');

  // Get active tab from URL or default to 'team-details'
  const activeTab = searchParams.get('tab') || 'team-details';

  // Handle tab change
  const handleTabSelect = (key) => {
    setSearchParams({ tab: key });
  };

  // Add these memoized functions at the top level of your component:
  const fetchCompanyJobGroup = useCallback(async () => {
    if (isNewTeam) return;
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/job-groups/${jobGroupId}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch team data.');
      }
      setJobGroup(data.jobGroup);
      setHasEditPermission(data.hasEditPermission);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [jobGroupId, isNewTeam]);

  const fetchUsersNotInJobGroup = useCallback(async () => {
    if (!jobGroup) return;
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/job-groups/users/users-not-in-job-group`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ usersAlreadyInJobGroup: jobGroup.userRoles }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch users.');
      }
      setUsersNotInJobGroup(data.users);
    } catch (error) {
      toast.error(error.message);
    }
  }, [jobGroup]);

  // Update these useEffect hooks:
  useEffect(() => {
    fetchCompanyJobGroup();
  }, [fetchCompanyJobGroup]);

  useEffect(() => {
    if (showModal) {
      fetchUsersNotInJobGroup();
      fetchUserRoleOptions();
    }
  }, [showModal, fetchUsersNotInJobGroup]);

  useEffect(() => {
    const date = new Date();
    const timeString = date.toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    });
    setTimezoneAbbreviation(timeString.split(' ')[2] || '');
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const fetchUserRoleOptions = async () => {
    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/job-groups/users/roles`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch roles.');
      }
      setUserRoleOptions(data.roles);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleModalSubmit = (payload) => {
    setIsSaving(true);
    try {
      let updatedUserRoles = [...(jobGroup.userRoles || [])];

      // Update the switch statement in handleModalSubmit:
      switch (payload.type) {
        case MODAL_TYPES.ADD: {
          const newUserRole = {
            userRole: {
              createdAt: new Date().toISOString(),
              user: {
                id: payload.user.id,
                firstName: payload.user.firstName,
                lastName: payload.user.lastName,
                email: payload.user.email,
              },
              role: payload.role,
            },
          };
          updatedUserRoles.push(newUserRole);
          break;
        }
        case MODAL_TYPES.UPDATE: {
          console.log(payload.user);
          updatedUserRoles = updatedUserRoles.map((userRole) =>
            userRole.userRole.user.id === payload.user.id
              ? {
                  ...userRole,
                  userRole: {
                    ...userRole.userRole,
                    createdAt: userRole.userRole.createdAt,
                    user: userRole.userRole.user,
                    role: payload.role,
                  },
                }
              : userRole
          );
          break;
        }
        case MODAL_TYPES.REMOVE: {
          updatedUserRoles = updatedUserRoles.filter(
            (userRole) => userRole.userRole.user.id !== payload.user.id
          );
          break;
        }
        default: {
          console.warn('Unhandled modal type:', payload.type);
          break;
        }
      }

      setJobGroup((prev) => ({
        ...prev,
        userRoles: updatedUserRoles,
      }));

      setHasChanges(true);
      handleCloseModal();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      const endpoint = isNewTeam
        ? `${BACKEND_API_URL}/api/job-groups/new`
        : `${BACKEND_API_URL}/api/job-groups/${jobGroupId}`;

      const method = isNewTeam ? 'POST' : 'PUT';

      const response = await fetch(endpoint, {
        method,
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ jobGroup }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          isNewTeam
            ? data?.error || 'Failed to create team.'
            : data?.error || 'Failed to save team settings.'
        );
      }

      if (isNewTeam) {
        toast.success('Team created successfully.');
        navigate(`/teams/${data.jobGroup.id}/edit`);
      } else {
        setJobGroup(data.jobGroup);
        toast.success('Team settings saved successfully.');
        setHasChanges(false);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      // Confirm the deletion with the user
      const confirmation = window.confirm(
        'Are you sure you want to delete this Team? This action cannot be undone.'
      );
      if (!confirmation) return;

      // Call the DELETE API
      const response = await fetch(
        `${BACKEND_API_URL}/api/job-groups/${jobGroupId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const data = await response.json();
        toast.error(`Error: ${data?.message}`, {
          position: 'top-right',
          autoClose: 5000,
        });
        return;
      }

      navigate('/teams');
    } catch (error) {
      console.error('Error deleting team:', error);
      toast.error('An unexpected error occurred while deleting the Team.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  const handleOpenModal = (type, member = null) => {
    setModalType(type);
    setSelectedMember(member);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMember(null);
    setModalType(MODAL_TYPES.ADD);
  };

  return (
    <Container
      className="mt-5"
      style={{ maxWidth: '1200px', margin: '0 auto' }}
    >
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <AppPageTitle title={isNewTeam ? 'New Team' : jobGroup?.name} />{' '}
        <div className="d-flex align-items-center mb-4">
          <Button
            onClick={handleSaveSettings}
            disabled={(!hasChanges && !isNewTeam) || isSaving}
            className="btn btn-primary fw-bold"
            size="sm"
          >
            {isSaving ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                className="me-2"
              />
            ) : isNewTeam ? (
              'Save New Team'
            ) : (
              'Save Changes'
            )}
          </Button>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginLeft: '10px',
            }}
          >
            {/* Dropdown menu button */}
            <div className="dropdown">
              <button
                className="btn"
                type="button"
                id="actionDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  backgroundColor: 'transparent',
                  padding: '5px 10px',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                &#8942; {/* Vertical ellipsis icon */}
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="actionDropdown"
              >
                <>
                  <li>
                    <button className="dropdown-item" onClick={handleDelete}>
                      Delete
                    </button>
                  </li>
                </>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        id="team-tabs"
        className="mb-3 small-font"
      >
        <Tab
          eventKey="team-details"
          title={<span style={{ fontWeight: 'bold' }}>Details</span>}
        >
          <Card className="mb-4">
            <Card.Body className="p-4 small-font">
              {isLoading ? (
                <div className="text-center my-5">
                  <Spinner animation="border" role="status" /> Loading...
                </div>
              ) : (
                <Form>
                  <Form.Group controlId="externalName" className="mb-3">
                    <Form.Label>
                      External Name
                      <RequiredAsterisk />
                      <InfoTooltip message="Required. The name of the company that applicants hear during the AI phone interview. This field is used to automatically populate the 'Company Name' when creating or editing a job." />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={jobGroup?.publicName || ''}
                      onChange={(e) => {
                        setJobGroup((prev) => ({
                          ...prev,
                          publicName: e.target.value,
                        }));
                        setHasChanges(true);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="internalName" className="mb-3">
                    <Form.Label>
                      Internal Name
                      <RequiredAsterisk />
                      <InfoTooltip message="Required. This internal team name is not seen by applicants." />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={jobGroup?.name || ''}
                      onChange={(e) => {
                        setJobGroup((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }));
                        setHasChanges(true);
                      }}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="internalId" className="mb-3">
                    <Form.Label>
                      Internal Id{' '}
                      <InfoTooltip message="Optional label field. This is not seen by applicants." />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={jobGroup?.alternateId || ''}
                      onChange={(e) => {
                        setJobGroup((prev) => ({
                          ...prev,
                          alternateId: e.target.value,
                        }));
                        setHasChanges(true);
                      }}
                    />
                  </Form.Group>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Tab>
        <Tab
          eventKey="team-members"
          title={<span style={{ fontWeight: 'bold' }}>Members</span>}
        >
          <Card className="mb-4">
            <Card.Body className="p-4 small-font">
              <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
                <div className="d-flex align-items-center">
                  <span className="small-font fw-bold me-2">Members</span>
                </div>
                <Button
                  variant="success"
                  className="mb-3"
                  size="sm"
                  onClick={() => handleOpenModal(MODAL_TYPES.ADD)}
                  disabled={!hasEditPermission && !isNewTeam}
                >
                  Add User
                </Button>
              </div>

              {isLoading ? (
                <div className="text-center my-5">
                  <Spinner animation="border" role="status" /> Loading...
                </div>
              ) : (
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th>Updated At ({timezoneAbbreviation})</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th className="text-center">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobGroup?.userRoles?.map((userRole, index) => (
                      <tr key={index}>
                        <td>{formatDate(userRole.updatedAt)}</td>
                        <td>{userRole.userRole.user.firstName}</td>
                        <td>{userRole.userRole.user.lastName}</td>
                        <td>{userRole.userRole.user.email}</td>
                        <td>{userRole.userRole.role.friendlyName}</td>
                        <td>
                          <div className="d-flex gap-3 justify-content-center align-items-center">
                            <PencilSquare
                              className={`cursor-pointer ${
                                !hasEditPermission ? 'text-muted' : ''
                              }`}
                              onClick={
                                hasEditPermission
                                  ? () =>
                                      handleOpenModal(MODAL_TYPES.UPDATE, {
                                        id: userRole.userRole.user.id,
                                        firstName:
                                          userRole.userRole.user.firstName,
                                        lastName:
                                          userRole.userRole.user.lastName,
                                        email: userRole.userRole.user.email,
                                        role: userRole.userRole.role.id,
                                        roleDescription:
                                          userRole.userRole.role.description,
                                      })
                                  : null
                              }
                            />
                            <PersonDash
                              className={`cursor-pointer text-danger ${
                                !hasEditPermission ? 'text-muted' : ''
                              }`}
                              onClick={
                                hasEditPermission
                                  ? () =>
                                      handleOpenModal(MODAL_TYPES.REMOVE, {
                                        id: userRole.userRole.user.id,
                                        firstName:
                                          userRole.userRole.user.firstName,
                                        lastName:
                                          userRole.userRole.user.lastName,
                                        email: userRole.userRole.user.email,
                                        role: userRole.userRole.role.id,
                                      })
                                  : null
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>

      <TeamMemberModal
        show={showModal}
        onHide={handleCloseModal}
        modalType={modalType}
        selectedMember={selectedMember}
        userRoleOptions={userRoleOptions}
        usersNotInJobGroup={usersNotInJobGroup}
        onSubmit={handleModalSubmit}
        isLoading={isSaving}
      />
    </Container>
  );
};

export default CompanyTeamEditPage;
