import React, { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

const SystemVariablesModal = ({ show, onHide }) => {
  const [copiedVariableName, setCopiedVariableName] = useState(null);

  const variables = [
    '{{interviewerName}}',
    '{{dayOfWeek}}',
    '{{jobTitle}}',
    '{{jobCompanyName}}',
    '{{jobDescription}}',
    '{{jobResponsibilities}}',
    '{{jobBenefits}}',
    '{{jobInterviewQuestionsWithPausePrompt}}',
    '{{jobInterviewClarificationQuestions}}',
  ];

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedVariableName(text);
      setTimeout(() => setCopiedVariableName(null), 5000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>System Prompt Variables</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-unstyled">
            {variables.map((variable, index) => (
              <div
                key={index}
                onClick={() => copyToClipboard(variable)}
                className="py-2 px-3 hover:bg-gray-100"
                style={{ cursor: 'pointer' }}
              >
                {variable}
              </div>
            ))}
          </div>
          {copiedVariableName && (
            <Alert variant="success" className="mt-4">
              <strong>{copiedVariableName}</strong> copied to clipboard
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={onHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SystemVariablesModal;
