import React, { useEffect, useState } from 'react';
import {
  Table,
  Badge,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { PencilSquare, BriefcaseFill } from 'react-bootstrap-icons';
import AppPageTitle from '../components/app/AppPageTitle';
import { Link, useNavigate } from 'react-router-dom';
import './CompanyAssessmentsPage.css';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const CompanyAssessmentsPage = () => {
  const navigate = useNavigate();
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCreatePermission, setHasCreatePermission] = useState(false);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [sortColumn, setSortColumn] = useState('publishedAt');
  const [timezoneAbbreviation, setTimezoneAbbreviation] = useState('');
  const [newDraftLoading, setNewDraftLoading] = useState(false);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState({
    enabledForJobs: true,
    notEnabledForJobs: true,
    draft: true,
    archived: false,
  });

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/assessments`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch assessments');
        }

        const data = await response.json();
        setAssessments(data.assessments);
        setIsLoading(false);
        setFilteredAssessments(data.assessments); // Initialize the filtered list
        setHasCreatePermission(data.hasCreatePermission);
      } catch (error) {
        console.error('Error fetching assessments:', error);
      }
    };

    fetchAssessments();
  }, []);

  useEffect(() => {
    const date = new Date();
    const timeString = date.toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    });
    setTimezoneAbbreviation(timeString.split(' ')[2] || '');
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };

  // Handle search and filtering changes
  useEffect(() => {
    const filterAssessments = () => {
      const filtered = assessments.filter((assessment) => {
        const matchesSearch = assessment.name
          .toLowerCase()
          .includes(searchQuery);

        const matchesFilter =
          (filters.enabledForJobs && assessment.status.state === 'Enabled') ||
          (filters.notEnabledForJobs &&
            assessment.status.state === 'Disabled') ||
          (filters.draft && assessment.status.state === 'Draft') ||
          (filters.archived && assessment.status.state === 'Archived');

        return matchesSearch && matchesFilter;
      });

      setFilteredAssessments(filtered);
    };

    filterAssessments();
  }, [assessments, searchQuery, filters]); // Only rerun when these dependencies change

  // Handle search input changes
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  // Handle filter toggles
  const handleFilterChange = (filterName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  // Sort the filtered assessments
  const sortedAssessments = [...filteredAssessments].sort((a, b) => {
    if (sortColumn === 'publishedAt') {
      if (a.isDraft && !b.isDraft) return -1; // Drafts appear at the top
      if (!a.isDraft && b.isDraft) return 1;

      const dateA = a.publishedAt ? new Date(a.publishedAt) : null;
      const dateB = b.publishedAt ? new Date(b.publishedAt) : null;

      if (!dateA) return sortOrder === 'DESC' ? 1 : -1;
      if (!dateB) return sortOrder === 'DESC' ? -1 : 1;

      return sortOrder === 'DESC' ? dateB - dateA : dateA - dateB;
    }

    if (sortColumn === 'activeJobCount') {
      return sortOrder === 'DESC'
        ? b.activeJobCount - a.activeJobCount
        : a.activeJobCount - b.activeJobCount;
    }

    return 0; // Default fallback
  });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      setSortColumn(column);
      setSortOrder('DESC'); // Default to descending order when switching columns
    }
  };

  const createDraftAssessment = async () => {
    setNewDraftLoading(true);
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/assessments/draft`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to create draft Call Script');
      }

      const data = await response.json();
      setNewDraftLoading(false);
      navigate(`/call-scripts/${data.assessment.id}/edit`);
    } catch (error) {
      console.error('Error creating draft Call Script:', error);
    } finally {
      setNewDraftLoading(false);
    }
  };

  return (
    <div className="wide-container small-font">
      {!isLoading && assessments && (
        <>
          <div>
            <AppPageTitle title="Call Scripts" />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 1 }}>
                <Form.Control
                  type="text"
                  placeholder="Search call scripts..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    width: '300px',
                    display: 'inline-block',
                    marginRight: '15px',
                  }}
                />
                <Form.Check
                  inline
                  label="Enabled for Jobs"
                  checked={filters.enabledForJobs}
                  onChange={() => handleFilterChange('enabledForJobs')}
                />
                <Form.Check
                  inline
                  label="Not Enabled for Jobs"
                  checked={filters.notEnabledForJobs}
                  onChange={() => handleFilterChange('notEnabledForJobs')}
                />
                <Form.Check
                  inline
                  label="Draft"
                  checked={filters.draft}
                  onChange={() => handleFilterChange('draft')}
                />
                <Form.Check
                  inline
                  label="Archived"
                  checked={filters.archived}
                  onChange={() => handleFilterChange('archived')}
                />
              </div>
              <Button
                variant="primary"
                size="sm"
                className="fw-bold"
                onClick={createDraftAssessment}
                disabled={newDraftLoading || !hasCreatePermission}
              >
                Create Call Script
              </Button>
            </div>
          </div>
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th
                  onClick={() => handleSort('publishedAt')}
                  style={{ cursor: 'pointer' }}
                >
                  Last Published At ({timezoneAbbreviation}){' '}
                  {sortOrder === 'ASC' ? '↑' : '↓'}
                </th>
                <th className="company-assessments-page-td-width">
                  Call Script Name
                </th>
                <th
                  onClick={() => handleSort('activeJobCount')}
                  style={{ cursor: 'pointer' }}
                >
                  # of Active Jobs{' '}
                  {sortColumn === 'activeJobsCount' &&
                    (sortOrder === 'ASC' ? '↑' : '↓')}
                </th>
                <th className="text-center">Enabled for Jobs?</th>
                <th className="text-center">Manage</th>
              </tr>
            </thead>
            <tbody>
              {sortedAssessments.map((assessment) => (
                <tr
                  key={`${assessment.id}-${assessment.name}-${assessment.isDraft}`}
                >
                  <td>
                    {assessment.publishedAt ? (
                      formatDate(assessment.publishedAt)
                    ) : (
                      <Badge bg={'info'}>{assessment.status.state}</Badge>
                    )}
                    {assessment.status.state !== 'Draft' &&
                      assessment.unSavedChanges?.hasChanges && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {`This Call Script has unpublished changes in the following fields:`}
                              <ul
                                style={{ margin: 0, padding: '5px 0 0 15px' }}
                              >
                                {assessment.unSavedChanges.fields.map(
                                  (field, index) => (
                                    <li
                                      key={index}
                                      style={{ fontSize: '0.85rem' }}
                                    >
                                      {field}
                                    </li>
                                  )
                                )}
                              </ul>
                            </Tooltip>
                          }
                        >
                          <span
                            className="text-warning"
                            style={{
                              display: 'inline-block',
                              width: '9px',
                              height: '9px',
                              backgroundColor: 'currentColor',
                              borderRadius: '50%',
                              marginLeft: '8px',
                            }}
                          ></span>
                        </OverlayTrigger>
                      )}
                  </td>
                  <td>{assessment.name}</td>
                  <td>
                    <BriefcaseFill className="me-2" />
                    <span>{assessment.activeJobsCount}</span>{' '}
                  </td>
                  <td className="text-center">
                    {['Enabled', 'Disabled', 'Draft'].includes(
                      assessment.status.state
                    ) ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip>
                            {assessment.status?.availableForJobsToolTip}
                          </Tooltip>
                        }
                      >
                        <Badge
                          bg={
                            assessment.status.state === 'Enabled'
                              ? 'success'
                              : assessment.status.state === 'Disabled'
                              ? 'secondary'
                              : 'info'
                          }
                          style={{ cursor: 'pointer' }} // Add pointer cursor for better UX
                        >
                          {assessment.status.jobAvailabilityColumn}
                        </Badge>
                      </OverlayTrigger>
                    ) : (
                      <Badge
                        bg={
                          assessment.status.state === 'Archived'
                            ? 'dark'
                            : assessment.status.state === 'Draft'
                            ? 'info'
                            : 'secondary' // Fallback for unknown statuses
                        }
                      >
                        {assessment.status.state}
                      </Badge>
                    )}
                  </td>
                  <td className="text-center">
                    <Link
                      to={`/call-scripts/${assessment.id}/edit`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      {/* Add space between count and icon */}
                      <PencilSquare
                        className="text-dark"
                        style={{ fontSize: '1rem' }}
                      />{' '}
                      {/* Larger icon */}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {!isLoading && assessments && assessments?.length === 0 && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-center mt-4">📞 No Call Scripts to see.</h3>

          {!hasCreatePermission && (
            <h4 className="mt-4">
              Make sure you’re part of a team with enabled and published Call
              Scripts.
            </h4>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyAssessmentsPage;
