import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Dropdown, Spinner, Alert } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import RequiredAsterisk from '../../appPages/RequiredAsterisk';

const MODAL_TYPES = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE',
};

const TeamMemberModal = ({
  show,
  onHide,
  modalType = MODAL_TYPES.ADD,
  selectedMember = null,
  userRoleOptions = [],
  usersNotInJobGroup = [],
  onSubmit,
  isLoading = false,
}) => {
  const [emailInput, setEmailInput] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (modalType === MODAL_TYPES.ADD) {
      setEmailInput('');
      setSelectedUser(null);
      setSelectedRole(null);
      setValidated(false);
    } else if (selectedMember) {
      setSelectedUser(selectedMember);
      setEmailInput(selectedMember.email);
      setSelectedRole(selectedMember.role);
    }
  }, [selectedMember, modalType]);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailInputChange = (event) => {
    const value = event.target.value;
    setEmailInput(value);
    if (validated) setValidated(false);

    if (value.length > 0 && modalType === MODAL_TYPES.ADD) {
      const filtered = usersNotInJobGroup.filter((user) =>
        `${user.firstName} ${user.lastName} ${user.email}`
          .toLowerCase()
          .includes(value.toLowerCase())
      );
      setFilteredUsers(filtered);

      const matchedUser = filtered.find((user) => user.email === value);
      if (!matchedUser) {
        setSelectedUser({ firstName: '', lastName: '', email: value });
      }
    } else {
      setFilteredUsers([]);
      setSelectedUser(null);
    }
  };

  const handleSelectUser = (user) => {
    setEmailInput(user.email);
    setSelectedUser(user);
    setFilteredUsers([]);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    if (selectedUser) {
      setSelectedUser((prevUser) => ({
        ...prevUser,
        role: role.id,
      }));
    }
  };

  const handleSubmit = () => {
    setValidated(true);
    if (!isValidEmail(emailInput)) return;

    const payload = {
      user: selectedUser,
      role: selectedRole,
      type: modalType,
    };
    onSubmit(payload);

    // Clear fields after submit
    setEmailInput('');
    setSelectedUser(null);
    setSelectedRole(null);
    setValidated(false);
    setFilteredUsers([]);
  };

  const getModalTitle = () => {
    switch (modalType) {
      case MODAL_TYPES.ADD:
        return 'Add Team Member';
      case MODAL_TYPES.UPDATE:
        return 'Update Team Member';
      case MODAL_TYPES.REMOVE:
        return 'Remove Team Member';
      default:
        return 'Team Member';
    }
  };

  const getSubmitButtonText = () => {
    switch (modalType) {
      case MODAL_TYPES.ADD:
        return 'Add Member';
      case MODAL_TYPES.UPDATE:
        return 'Update Member';
      case MODAL_TYPES.REMOVE:
        return 'Remove Member';
      default:
        return 'Submit';
    }
  };

  const getSubmitButtonVariant = () => {
    switch (modalType) {
      case MODAL_TYPES.ADD:
        return 'success';
      case MODAL_TYPES.UPDATE:
        return 'primary';
      case MODAL_TYPES.REMOVE:
        return 'danger';
      default:
        return 'primary';
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{getModalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="emailInput" className="mb-3">
            <Form.Label>
              Email <RequiredAsterisk />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Search or enter email"
              value={emailInput}
              onChange={handleEmailInputChange}
              autoComplete="off"
              disabled={modalType !== MODAL_TYPES.ADD}
              isInvalid={validated && emailInput && !isValidEmail(emailInput)}
            />
            {validated && emailInput && !isValidEmail(emailInput) && (
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address
              </Form.Control.Feedback>
            )}
            {filteredUsers.length > 0 && modalType === MODAL_TYPES.ADD && (
              <Dropdown.Menu show className="w-100">
                {filteredUsers.map((user) => (
                  <Dropdown.Item
                    key={user.id}
                    onClick={() => handleSelectUser(user)}
                  >
                    {user.firstName} {user.lastName} - {user.email}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Form.Group>
          {/* Rest of the form groups remain the same */}
          <Form.Group controlId="firstNameInput" className="mb-3">
            <Form.Label>
              First Name <RequiredAsterisk />
            </Form.Label>
            <Form.Control
              type="text"
              value={selectedUser?.firstName || ''}
              disabled={modalType === MODAL_TYPES.REMOVE}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  firstName: e.target.value,
                }))
              }
              required
            />
          </Form.Group>
          <Form.Group controlId="lastNameInput" className="mb-3">
            <Form.Label>
              Last Name <RequiredAsterisk />
            </Form.Label>
            <Form.Control
              type="text"
              value={selectedUser?.lastName || ''}
              disabled={modalType === MODAL_TYPES.REMOVE}
              onChange={(e) =>
                setSelectedUser((prevUser) => ({
                  ...prevUser,
                  lastName: e.target.value,
                }))
              }
              required
            />
          </Form.Group>
          {modalType !== MODAL_TYPES.REMOVE && (
            <Form.Group controlId="roleInput" className="mb-3">
              <Form.Label>
                Role <RequiredAsterisk />
              </Form.Label>
              <Form.Control
                as="select"
                value={
                  modalType === MODAL_TYPES.UPDATE
                    ? selectedMember?.role || ''
                    : selectedRole?.id || ''
                }
                onChange={(e) => {
                  const selectedRoleObj = userRoleOptions.find(
                    (role) => role.id === e.target.value
                  );
                  handleRoleSelect(selectedRoleObj);
                }}
              >
                <option value="" disabled>
                  Select a Role
                </option>
                {userRoleOptions.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.friendlyName}
                  </option>
                ))}
              </Form.Control>
              {(selectedRole?.description ||
                selectedMember?.roleDescription) && (
                <Alert variant="warning" className="mt-4">
                  <InfoCircle />
                  {` `}
                  {selectedRole?.description || selectedMember?.roleDescription}
                </Alert>
              )}
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={getSubmitButtonVariant()}
          disabled={!emailInput || isLoading}
          onClick={handleSubmit}
          size="sm"
        >
          {isLoading ? (
            <Spinner animation="border" size="sm" className="me-2" />
          ) : null}
          {getSubmitButtonText()}
        </Button>
        <Button variant="secondary" onClick={onHide} size="sm">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { TeamMemberModal, MODAL_TYPES };
