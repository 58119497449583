import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './DemoSignupPage.css';
import LogoSection from '../components/sections/LogoSection';
import { logos } from '../components/logos/atsLogos';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const DemoSignupPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    companySize: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const emailFromQuery = searchParams.get('email');
    if (emailFromQuery) {
      setFormData((prevData) => ({ ...prevData, email: emailFromQuery }));
    }
  }, [searchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${BACKEND_API_URL}/api/demo-signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        window.location.href = '/demo-signup-success';
      } else {
        const data = await response.json();
        setErrorMessage(
          data.message || 'Something went wrong. Please try again.'
        );
      }
    } catch (error) {
      setErrorMessage('Unable to reach the server. Please try again later.');
    }
  };

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Request a Demo | InstaHear.ai</title>
        <meta
          name="description"
          content="Try InstaHear for free. We'd love to show how InstaHear can help you screen and engage applicants."
        />
        <meta
          name="keywords"
          content="InstaHear.ai, free trial, demo, signup"
        />
      </Helmet>

      <div className="demo-signup-background">
        <div className="demo-content-container">
          <div className="demo-card-container">
            <form onSubmit={handleSubmit} className="demo-card">
              <div className="demo-card-header">
                <h3 className="mt-3">Try InstaHear for Free</h3>
                <p className="demo-card-subtitle">
                  See how InstaHear pre-qualifies and engages your ideal
                  customers and job applicants
                </p>
              </div>
              {/* Form Fields */}
              <div className="demo-form-group">
                <label htmlFor="email">Work Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your work email"
                  required
                />
              </div>
              {/* First Name and Last Name */}
              <div className="demo-form-group inline">
                <div>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Enter your last name"
                    required
                  />
                </div>
              </div>
              {/* Company Name and Dropdown */}
              <div className="demo-form-group inline">
                <div>
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Enter your company name"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="companySize">Company Size</label>
                  <select
                    id="companySize"
                    name="companySize"
                    value={formData.companySize}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1 - 20">1 - 20</option>
                    <option value="21 - 50">21 - 50</option>
                    <option value="51 - 100">51 - 100</option>
                    <option value="101 - 200">101 - 200</option>
                    <option value="201 - 1,000">201 - 1,000</option>
                    <option value="1,001 - 10,000">1,001 - 10,000</option>
                    <option value="10,001+">10,001+</option>
                    <option value="Unknown">Unknown</option>
                  </select>
                </div>
              </div>
              {errorMessage && (
                <p className="demo-error-message">{errorMessage}</p>
              )}
              <button
                type="submit"
                className="btn btn-ig-purple demo-submit-btn"
              >
                Submit
              </button>
              <p className="demo-tos">
                By submitting this form, you agree to our{' '}
                <a href="/privacy">Privacy Policy</a>.
              </p>
            </form>
          </div>
        </div>
      </div>
      <LogoSection
        id="integration-section"
        backgroundColor="#000"
        color="white"
        title="INTEGRATES WITH OVER 100+ PLATFORMS"
        logos={logos}
      />
    </div>
  );
};

export default DemoSignupPage;
