import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">
        INSTAHEAR LLC
        <br />
        Privacy Policy
      </h1>
      <p className="text-center">
        <strong>Effective Date:</strong> 10/17/2024
      </p>
      <div className="privacy-policy-content">
        <section className="mb-4">
          <h2>1. Information We Collect</h2>

          <p>
            When you use our services, including submitting forms for applicant
            screening, we collect certain personal information necessary for the
            proper functioning of the service. This information includes but is
            not limited to:
          </p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, contact details
              (email, phone number), and any other information provided during
              form submissions.
            </li>
            <li>
              <strong>Mobile Information:</strong> Mobile numbers are collected
              as part of the form entry and follow-up SMS communications during
              the screening process.
            </li>
            <li>
              <strong>Technical Information:</strong> IP addresses, browser
              type, operating system, and other data related to your interaction
              with our website.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2>2. How We Use Your Information</h2>
          <p>We use the collected information for the following purposes:</p>
          <ul>
            <li>To provide and manage our applicant screening services.</li>
            <li>
              To send SMS notifications and follow-up messages related to the
              applicant screening process.
            </li>
            <li>
              To respond to inquiries, provide support, and improve our
              services.
            </li>
            <li>To comply with legal and regulatory obligations.</li>
          </ul>
        </section>

        <section className="mb-4">
          <h2>3. Mobile Information Sharing</h2>
          <p>
            <strong>
              We do not share end-user mobile information with third parties or
              affiliates.
            </strong>{' '}
            Your mobile number is collected solely for the purpose of SMS
            communication during the applicant screening process. This
            information is kept confidential and is used strictly within
            InstaHear LLC.
          </p>
        </section>

        <section className="mb-4">
          <h2>4. End-User Information Sharing</h2>
          <p>
            <strong>
              We do not share any additional end-user information with third
              parties or affiliates.
            </strong>{' '}
            Any personal or technical information collected through our services
            is used only to deliver our services and improve your experience. We
            do not sell, trade, or rent any personal data to third parties.
          </p>
        </section>

        <section className="mb-4">
          <h2>5. SMS Communication and Consent</h2>
          <p>
            By submitting your mobile phone number during the form entry
            process, you consent to receive SMS messages from InstaHear LLC as
            part of the applicant screening process. Standard messaging rates
            may apply based on your mobile carrier’s service plan.
          </p>
          <p>
            You can opt-out of receiving SMS messages at any time by replying
            “STOP” to any SMS you receive from us. If you need help or have
            questions regarding our SMS service, you can reply “HELP” to any SMS
            or contact us at{' '}
            <a href="mailto:privacy@instahear.ai">privacy@instahear.ai</a>.
          </p>
        </section>

        <section className="mb-4">
          <h2>6. Data Retention</h2>
          <p>
            We retain personal information for as long as necessary to fulfill
            the purposes outlined in this Privacy Policy, including providing
            our services and complying with legal obligations. If you request
            deletion of your personal data, we will delete it in accordance with
            our legal and regulatory obligations.
          </p>
        </section>

        <section className="mb-4">
          <h2>7. Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal
            information from unauthorized access, use, or disclosure. However,
            no method of transmission over the Internet or electronic storage is
            completely secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section className="mb-4">
          <h2>8. Your Rights</h2>
          <p>
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li>
              <strong>Access:</strong> You can request access to the personal
              information we hold about you.
            </li>
            <li>
              <strong>Correction:</strong> You can request that we correct any
              inaccuracies in your information.
            </li>
            <li>
              <strong>Deletion:</strong> You can request that we delete your
              personal information, subject to legal obligations.
            </li>
            <li>
              <strong>Opt-Out:</strong> You can opt-out of receiving marketing
              communications at any time.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us at{' '}
            <a href="mailto:privacy@instahear.ai">privacy@instahear.ai</a>.
          </p>
        </section>

        <section className="mb-4">
          <h2>9. Children's Privacy</h2>
          <p>
            Our services are not intended for children under the age of 18. We
            do not knowingly collect personal information from individuals under
            18. If we become aware that we have inadvertently collected such
            information, we will take steps to delete it.
          </p>
        </section>

        <section className="mb-4">
          <h2>10. Third-Party Services and Links</h2>
          <p>
            Our website and services may contain links to third-party websites
            or services. We are not responsible for the privacy practices or
            content of third-party websites. We encourage users to review the
            privacy policies of any third-party services they interact with.
          </p>
        </section>

        <section className="mb-4">
          <h2>11. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal obligations. Any changes will be
            posted on this page, and the updated Privacy Policy will be
            effective as of the date indicated at the top.
          </p>
        </section>

        <section className="mb-4">
          <h2>12. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            how we handle your information, please contact us at:
          </p>
          <ul>
            <li>
              Email:{' '}
              <a href="mailto:privacy@instahear.ai">privacy@instahear.ai</a>
            </li>
            <li>Address: 784 S CLEARWATER LOOP STE R POST FALLS, ID 83854</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
