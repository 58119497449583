import React, { useState, useEffect } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { Stars } from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';
import './AiJobCreatorPage.css'; // Import specific CSS for this page
import 'bootstrap/dist/css/bootstrap.min.css';
import InfoTooltip from './InfoTooltip';
import Select from 'react-select';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const AiJobCreator = () => {
  const [jobPostingUrl, setJobPostingUrl] = useState('');
  const [jobPostingContent, setJobPostingContent] = useState('');
  const [jobGroupId, setJobGroupId] = useState('');
  const [jobGroups, setJobGroups] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [jobCreatorType, setJobCreatorType] = useState(null);

  const phrases = [
    'Getting job details',
    'Extracting job details',
    'Creating InstaHear job',
    'Creating InstaHear call script',
  ];

  const fetchJobGroups = async () => {
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/job-groups`, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch teams');
      }

      const data = await response.json();
      setJobGroups(data.jobGroups);
    } catch (error) {
      console.error('Error fetching jobGroups:', error);
    }
  };

  useEffect(() => {
    fetchJobGroups();
  }, []); // Empty dependency array means it runs once on mount.

  const handlePhraseRotation = () => {
    let index = 0;
    setCurrentPhrase(phrases[index]);
    const interval = setInterval(() => {
      index++;
      if (index < phrases.length) {
        setCurrentPhrase(phrases[index]);
      } else {
        clearInterval(interval);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
      setCurrentPhrase('');
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!jobPostingUrl && !jobPostingContent) {
      toast.error(
        'Please provide either a Job Posting URL or Job Posting Content.',
        {
          position: 'top-right',
          autoClose: 3000,
        }
      );
      return;
    }

    setIsSubmitting(true);
    const stopPhraseRotation = handlePhraseRotation();

    try {
      const response = await fetch(
        `${BACKEND_API_URL}/api/ai-jobs/create-from-url-or-content`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            url: jobPostingUrl,
            content: jobPostingContent,
            jobGroupId,
          }),
          credentials: 'include',
        }
      );

      if (response.ok) {
        const data = await response.json();
        setJobId(data.jobId);
        setJobCreatorType(data.type);
        setShowModal(true);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'An error occurred. Please try again.', {
          position: 'top-right',
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } finally {
      setIsSubmitting(false);
      stopPhraseRotation();
    }
  };

  return (
    <div className="container my-5">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <JobCompanyLogo
        src="https://storage.googleapis.com/instahear-react-images/public-session-scorecard-images/instahear-logo-inverted-black-blue.png"
        alt="InstaHear Logo"
      />

      <div className="form-container bg-white px-4 py-5 rounded shadow">
        <h5 className="text-center mb-4">
          <Stars style={{ color: '#6f00f9', marginRight: '8px' }} />
          AI Job Creator
        </h5>
        <p className="pb-3 text-center subtitle-demo-form">
          Enter a job posting URL or paste content below to create an InstaHear
          job, call script and interview questions.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="jobGroup" className="form-label">
              Team{' '}
              <InfoTooltip
                message={`Groups jobs by location and limits access to authorized Team members.`}
              />
            </label>
            <Select
              className="small-font"
              styles={{
                container: (base) => ({ ...base, width: '100%' }),
              }}
              options={jobGroups}
              onChange={(selectedOption) => {
                setJobGroupId(selectedOption?.value);
              }}
              placeholder="Select a Team"
              isClearable
            />
          </div>

          <div className="mb-3">
            <label htmlFor="jobPostingUrl" className="form-label">
              Job Posting URL
            </label>
            <input
              type="text"
              id="jobPostingUrl"
              className="form-control"
              placeholder="https://example.com/job-posting"
              value={jobPostingUrl}
              onChange={(e) => setJobPostingUrl(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="jobPostingContent" className="form-label">
              Job Posting Content{' '}
            </label>
            <InfoTooltip
              message={`If the Job Posting URL throws errors, paste the content of
                    the job posting here instead.`}
            />
            <textarea
              id="jobPostingContent"
              className="form-control"
              rows="10"
              placeholder="Paste job posting content here"
              value={jobPostingContent}
              onChange={(e) => setJobPostingContent(e.target.value)}
              style={{ resize: 'vertical' }}
            ></textarea>
          </div>

          <button
            type="submit"
            className="btn btn-primary w-100 fw-bold mt-3"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            Create Job
          </button>
        </form>

        {isSubmitting && currentPhrase && (
          <div className="text-center mt-4">
            <div className="ai-job-creator-phrase-container">
              <span className="ai-job-creator-phrase">{currentPhrase}</span>
              <span className="ai-job-creator-ellipsis"></span>
            </div>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Stars style={{ color: '#6f00f9', marginRight: '8px' }} />
            Job Successfully Created
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Here is the link to the InstaHear job and call script created using
          the ${jobCreatorType}.`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => (window.location.href = `/jobs/${jobId}/edit`)}
          >
            View Job
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AiJobCreator;
