import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './HomePage.css'; // Import the custom CSS
import './HomePageNavbar.css'; // Import the custom CSS
import FeatureBenefitsSection from '../components/sections/FeatureBenefitsSection';
import FAQSection from '../components/sections/FAQSection';
import StatsSection from '../components/stats/StatsSectionHiring';
import VerticalTabsSection from '../components/sections/VerticalTabsSection';
import LogoSection from '../components/sections/LogoSection';
import BannerSection from '../components/sections/BannerSection';
import { logos } from '../components/logos/atsLogos';
import { howItWorksTabs } from '../components/data/howItWorksTabs';

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const ForHiringPage = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const navigateToDemoSignup = (email) => {
    const query = new URLSearchParams({ email }).toString();
    navigate(`/demo-signup?${query}`);
  };

  const handleGetDemo = async (e) => {
    e.preventDefault();

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      setSuccessMessage('');
      return;
    } else {
      navigateToDemoSignup(email);
    }
  };

  const features = [
    {
      emphasis: 'Automatic ATS updates',
      content: 'Candidate records updated in real-time.',
    },
    {
      emphasis: 'Instant Engagement',
      content: 'Eliminate phone tag and manual outreach.',
    },
    {
      emphasis: '24/7 Phone Interviews',
      content: 'Screen applicants outside business hours.',
    },
    {
      emphasis: 'Customized Knockout Questions',
      content: 'Filter out unqualified applicants.',
    },
    {
      emphasis: 'Powerful Reporting',
      content: 'Analytics show where applicants are falling off.',
    },
    {
      emphasis: 'Reduce Ad-Spend',
      content: 'Spend less on Indeed, etc by converting faster.',
    },
  ];

  const featureButtons = [
    { name: 'Recruiters' },
    { name: 'Talent Aquisition' },
    { name: 'HR Admins' },
    { name: 'Founders' },
    { name: 'CEOs' },
  ];

  return (
    <div>
      <div className="homepage-container">
        {/* Hero Section */}
        <section className="homepage-hero-section d-flex align-items-center">
          <div className="container desktop-container">
            <div className="row">
              {/* Left Column */}
              <div className="col-lg-6 col-md-12 homepage-hero-content">
                <h1 className="hero-title">
                  24<span style={{ padding: '0 5px' }}>/</span>7 Instant
                  Applicant Screening
                </h1>
                <p className="homepage-hero-subtitle">
                  Screen and interview your applicants within minutes of them
                  applying to your jobs.
                </p>
                <form
                  className="get-demo-form d-flex align-items-center"
                  onSubmit={handleGetDemo}
                >
                  <input
                    type="email"
                    className="form-control demo-input"
                    placeholder="Enter your work email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" className="btn btn-ig-blue demo-button">
                    Get a Demo
                  </button>
                </form>
                {successMessage && (
                  <p className="success-message text-success mt-2">
                    {successMessage}
                  </p>
                )}
                {errorMessage && (
                  <p className="error-message text-danger mt-2">
                    {errorMessage}
                  </p>
                )}
              </div>
              {/* Right Column */}
              <div className="col-lg-6 col-md-12 homepage-hero-image">
                <img
                  src={
                    'https://storage.googleapis.com/instahear-react-images/hero-section-images/homepage-hero-section-iphone-new.png'
                  } // Replace with your actual image URL
                  alt="Placeholder"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <LogoSection
          id="integration-section"
          backgroundColor="#000"
          color="white"
          title="INTEGRATES WITH 100+ ATS PLATFORMS"
          logos={logos}
        />

        {/* How It Works Section */}
        <VerticalTabsSection tabs={howItWorksTabs} />

        {/* Stats Section */}
        <StatsSection id="stats" />

        {/* Features Section */}
        <FeatureBenefitsSection
          id="features"
          featureButtons={featureButtons}
          subtitle={
            'Hire more qualified applicants by engaging and interviewing\nthem faster than anyone else.'
          }
          imageSrc={`https://storage.googleapis.com/instahear-react-images/feature-section-images/hr-office-women-shaking-hands.jpg`}
          features={features}
        />

        {/* FAQ Section */}
        <section id="faq" className="faq">
          <FAQSection />
        </section>

        {/* Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="black"
          color="white"
          title="Ready to transform your hiring?"
          subtitle="Activate InstaHear on your ATS to experience the hiring advantages with instant AI interviews."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-blue cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default ForHiringPage;
