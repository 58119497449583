import React from 'react';
import './StatsSection.css';

const StatsSectionHomepage = () => {
  return (
    <section className="stats-section">
      <div className="stats-container">
        <h2 className="stats-section-title">
          Accelerate your phone screening with AI
        </h2>
        <div className="stats-columns">
          <div className="stats-column">
            <h2 className="stats-heading">
              <span className="gradient-text">98</span>
              <span className="percentage">%</span>
            </h2>
            <h3 className="stats-subheading">
              calls completed
              <br />
              <strong className="stats-subheading-emphasis">
                Within 5 Minutes
              </strong>{' '}
              of customer inbound request
            </h3>
          </div>
          <div className="stats-column">
            <h2 className="stats-heading">
              <span className="gradient-text">140</span>
              <span className="percentage">+</span>
            </h2>
            <h3 className="stats-subheading">
              languages supported with
              <br />{' '}
              <strong className="stats-subheading-emphasis">
                More than 800 AI Voices
              </strong>{' '}
              and regional dialects
            </h3>
          </div>
          <div className="stats-column">
            <h2 className="stats-heading">
              <span className="gradient-text">62</span>
              <span className="percentage">%</span>
            </h2>
            <h3 className="stats-subheading">
              calls completed
              <br />{' '}
              <strong className="stats-subheading-emphasis">
                Outside of Business Hours
              </strong>{' '}
              Monday to Friday 9am-5pm
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSectionHomepage;
