import React from 'react';
import { Card, Button } from 'react-bootstrap';
import HeroSection from '../components/sections/HeroSection';
import LogoSection from '../components/sections/LogoSection';
import { logos } from '../components/logos/atsLogos';
import './PricingPage.css';
import { Helmet } from 'react-helmet-async';
import { CheckCircle2, Gift, ArrowRight } from 'lucide-react';

const PricingPage = () => {
  const crmLogos = (logos) => {
    return logos.map((logo) => logo);
  };
  return (
    <div>
      <div className="homepage-container">
        <Helmet>
          <title>Pricing | InstaHear.ai</title>
          <meta
            name="description"
            content="Try InstaHear risk-free for 30 days. Pay-As-You-Go pricing for AI call handling."
          />
          <meta
            name="keywords"
            content="InstaHear.ai, pricing, try for free, free trial"
          />
        </Helmet>

        <HeroSection
          id="why-leadqual-hero-section"
          background="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          color="#fff"
          title="Simple\nPay-As-You-Go\nPricing"
          mobileTitle="Simple\nPay-As-You-Go\nPricing"
          subtitle="Only pay for what you use.\nNo contracts. No subscriptions. No hidden fees."
          mobileSubtitle="Only pay for what you use.\nNo contracts. No subscriptions.\nNo hidden fees."
        />

        <div
          style={{
            background:
              'radial-gradient(circle, rgba(0, 249, 235, 0.1) 25%, white 80%)',
            padding: '4rem 0',
          }}
        >
          <div className="container desktop-container">
            <div className="d-flex flex-column flex-md-row gap-5 gap-md-4 justify-content-center">
              {/* Main Pricing Card */}
              <Card
                className="shadow-lg mx-auto h-auto"
                style={{ maxWidth: '500px', alignSelf: 'flex-start' }}
              >
                <Card.Body className="p-5">
                  <div className="text-center mb-4">
                    <h2 className="display-3 fw-bold">
                      $1.99<span className="fs-3 text-muted">/minute</span>
                    </h2>
                    <p className="text-muted">
                      Pay-As-You-Go pricing for AI call handling
                    </p>
                  </div>

                  <div className="mb-4 pricing-card-features">
                    {[
                      'No minimum spend requirements',
                      '24/7 AI call handling capability',
                      'Inbound calls and text message follow-ups',
                      'Instant setup, no technical knowledge required',
                      'Real-time call transcripts and analytics',
                      'Prepaid minutes expire one year after purchase',
                    ].map((feature, index) => (
                      <div key={index} className="d-flex gap-3 mb-3">
                        <CheckCircle2 className="text-success flex-shrink-0" />
                        <p className="mb-0">{feature}</p>
                      </div>
                    ))}
                  </div>

                  <div className="text-center mb-4 border-top pt-4">
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <img
                        src="https://storage.googleapis.com/instahear-react-images/pricing-page-assets/instahear-money-back-guarantee.png"
                        alt="100% Money Back Guarantee"
                        className="mb-2 money-back-guarantee-image"
                      />
                      <div className="flex-column">
                        <p className="money-back-guarantee-text text-muted fw-bold mb-0 fs-6">
                          Love it, or Your Money Back
                        </p>
                        <p className="money-back-guarantee-text text-muted mb-0 fs-6">
                          30 days to try us risk-free.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <Button className="btn-ig-purple gap-2">
                      Try for Free
                    </Button>
                  </div>
                </Card.Body>
              </Card>

              {/* Free Trial Section */}
              <div className="mx-auto mt-2" style={{ maxWidth: '500px' }}>
                <div className="shadow-lg pricing-page-free-trial-card-section mb-4">
                  <h3 className="h4 fw-bold d-flex align-items-center gap-2 mb-3 pricing-page-free-trial-card-title ">
                    <Gift className="text-primary" color="#00b3a9" size={30} />
                    Claim your 300 free minutes
                  </h3>
                  <div className="mb-4">
                    {[
                      "Make a test call to one of our AI assistants, and we'll text you a special offer to unlock 300 free AI call minutes",
                      '300 free minutes covers about 50 calls',
                      'Free minutes expire in 7 days after self-service signup',
                      'Offer must be redeemed within 72 hours after completing a test call',
                    ].map((feature, index) => (
                      <div key={index} className="d-flex gap-3 mb-3">
                        {index === 0 ? (
                          <p className="mb-0 ms-0 pricing-page-free-trial-feature-item">
                            {feature}
                          </p>
                        ) : (
                          <>
                            <ArrowRight
                              className="text-primary flex-shrink-0"
                              size={20}
                              color="#00b3a9"
                            />
                            <p className="mb-0 pricing-page-free-trial-feature-item">
                              {feature}
                            </p>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="shadow-lg pricing-page-free-trial-card-section mb-4">
                  <h3 className="h4 fw-bold d-flex align-items-center gap-2 mb-3 pricing-page-free-trial-card-title">
                    <Gift className="text-primary" color="#00b3a9" size={55} />
                    Get 3,000 free minutes with any paid integration
                  </h3>
                  <div>
                    {[
                      'Book a Discovery Meeting within 48 hours of completing a test call to one of our AI assistants, and get $6,000 in free minutes with a one-time paid integration.',
                      '3,000 free minutes covers about 500 calls',
                      'Free minutes expire 30 days after paid CRM or ATS integration is completed',
                    ].map((feature, index) => (
                      <div key={index} className="d-flex gap-3 mb-3">
                        {index === 0 ? (
                          <p className="mb-0 ms-0 pricing-page-free-trial-feature-item">
                            {feature}
                          </p>
                        ) : (
                          <>
                            <ArrowRight
                              className="text-primary flex-shrink-0"
                              color="#00b3a9"
                              size={20}
                            />
                            <p className="mb-0 pricing-page-free-trial-feature-item">
                              {feature}
                            </p>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LogoSection
          id="integration-section"
          backgroundColor="black"
          color="#fff"
          title="INTEGRATES WITH OVER 100+ PLATFORMS"
          logos={crmLogos(logos)}
        />
      </div>
    </div>
  );
};

export default PricingPage;
