import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Card, Container, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import JobCompanyLogo from '../components/jobs/JobCompanyLogo';

// Add this CSS to your global styles or index.css
const globalStyles = `
html, body, #root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
}
`;

export let BACKEND_API_URL = 'https://api.instahear.ai';

if (process.env.REACT_APP_ENV === 'development') {
  BACKEND_API_URL = 'http://localhost:3001';
}

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('info');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // Add global styles
    const styleSheet = document.createElement('style');
    styleSheet.innerText = globalStyles;
    document.head.appendChild(styleSheet);

    const checkAuthToken = async () => {
      try {
        const response = await fetch(`${BACKEND_API_URL}/api/auth/check`, {
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          if (data.isAuthenticated) {
            navigate('/applications');
          }
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthToken();

    // Cleanup styles
    return () => styleSheet.remove();
  }, [navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${BACKEND_API_URL}/api/magic-link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('Check your email for a magic link to log in!');
        setAlertVariant('success');
      } else {
        const errorData = await response.json();
        const additionalMessage = errorData.message
          ? ` ${errorData.message}`
          : '';
        setMessage(`Failed to send magic link. ${additionalMessage}`);
        setAlertVariant('danger');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
      setAlertVariant('danger');
    }
  };

  if (loading) return null;

  return (
    <div
      style={{
        minHeight: '100vh',
        background:
          'radial-gradient(circle at 50% 40%, rgb(0, 249, 235) 0%, rgba(0, 0, 0, 0.8) 40%, black 100%)',
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: '15vh',
        justifyContent: 'center',
      }}
    >
      <Container className="d-flex justify-content-center align-items-center py-4">
        <Card style={{ width: '100%', maxWidth: '400px' }}>
          <Card.Body>
            <div className="py-3">
              <JobCompanyLogo
                src="https://storage.googleapis.com/instahear-react-images/public-session-scorecard-images/instahear-logo-inverted-black-blue.png"
                alt="Company Logo"
                width={'50%'}
              />
              <h4 className="text-center pb-2">CLIENT LOGIN</h4>
            </div>
            {message && <Alert variant={alertVariant}>{message}</Alert>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button className="w-100 btn-ig-purple mt-3" type="submit">
                Send Magic Link
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default LoginPage;
