import React from 'react';
import HowItWorksDemoGrid from '../components/homepage-how-it-works/HowItWorksDemoGrid';
import BannerSection from '../components/sections/BannerSection';
import LogoSection from '../components/sections/LogoSection';
import HeroSection from '../components/sections/HeroSection';
import { logos } from '../components/logos/atsLogos';

const HowItWorksPage = () => {
  return (
    <div>
      <div className="homepage-container">
        <HeroSection
          id="why-leadqual-hero-section"
          background="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          color="#fff"
          title="How It Works"
          mobileTitle="How It Works"
          subtitle="Try one our AI assistants to experience how InstaHear can automate your inbound calls\n"
          mobileSubtitle="Try one our AI assistants to experience how InstaHear can automate your inbound calls\n"
        />

        {/* How It Works Demo Grid */}
        <HowItWorksDemoGrid showTitle={false} />

        {/* ATS logos */}
        <LogoSection
          id="integration-section"
          backgroundColor="#00b3a9"
          color="white"
          title="INTEGRATES WITH OVER 100+ PLATFORMS"
          logos={logos}
        />

        {/* Banner Section */}
        <BannerSection
          id="ready-to-2x-your-hiring"
          backgroundColor="black"
          color="white"
          title="Ready to automate your call handling?"
          subtitle="Activate InstaHear on your CRM or ATS to experience the advantages with instant AI phone screens."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-purple cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default HowItWorksPage;
