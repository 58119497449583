import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check } from 'react-bootstrap-icons';
import './FeatureBenefitsSection.css';
import FeatureButtonRow from '../features/FeatureButtonRow';

const FeatureBenefitsSection = ({
  id = 'features', // Default ID for the section
  title = 'Your ultimate co-pilot for efficient hiring',
  subtitle,
  description,
  carouselItems, // Array of carousel items (optional)
  imageSrc, // Static image source (optional)
  reverse,
  features,
  featureButtons,
  backgroundColor,
  color,
}) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-rotate carousel items if carouselItems exist
  useEffect(() => {
    if (carouselItems) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
        );
      }, 7000);

      return () => clearInterval(interval);
    }
  }, [carouselItems]);

  return (
    <section id={id} className="pt-5 pb-5" style={{ backgroundColor, color }}>
      <div className="container desktop-container">
        <div className={`feature-benefits-section ${reverse ? 'reverse' : ''}`}>
          <h2 className="features-section-title mb-4">{title}</h2>
          {featureButtons && (
            <div className="feature-button-row">
              <FeatureButtonRow featureButtons={featureButtons} />
            </div>
          )}
          <div className="feature-section-content">
            <div className="feature-section-text-content">
              <div
                className="features-section-subtitle"
                style={{ whiteSpace: 'pre-line' }}
              >
                {subtitle}
              </div>
              <p className="feature-section-description">{description}</p>
              {/* Feature List */}
              <ul className="feature-section-list">
                {features.map((feature, index) => (
                  <li key={index} className="feature-section-list-item">
                    <Check className="feature-section-icon" />
                    <div className="feature-section-list-item-text">
                      <div className="feature-section-list-item-emphasis">
                        {feature.emphasis}
                      </div>
                      <div>{feature.content}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="feature-section-image-content">
              {carouselItems ? (
                <div className="feature-section-carousel">
                  {carouselItems.map((item, index) => (
                    <div
                      key={index}
                      className={`feature-section-carousel-item ${
                        index === currentIndex ? 'active' : ''
                      }`}
                    >
                      <div className="feature-section-stats-column">
                        <h2 className="feature-section-stats-heading">
                          <span className="feature-section-gradient-text">
                            {item.number}
                          </span>
                          <span className="feature-section-percentage">
                            {item.metric}
                          </span>
                        </h2>
                        <h3 className="feature-section-stats-subheading">
                          {item.textLine1}
                          <br />
                          <strong className="feature-section-stats-subheading-emphasis">
                            {item.textLine2}
                          </strong>{' '}
                          {item.textLine3}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                imageSrc && (
                  <img
                    src={imageSrc}
                    alt={subtitle}
                    className="feature-section-image"
                  />
                )
              )}
            </div>
          </div>
          <div className="features-section-button-container">
            <div>
              <button
                className="btn btn-ig-purple cta-btn my-4"
                onClick={() => navigate('/demo-signup')}
              >
                Get a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureBenefitsSection;
