import React from 'react';
import BannerSection from '../components/sections/BannerSection';
import LogoSection from '../components/sections/LogoSection';
import { logos } from '../components/logos/atsLogos';
import HeroSection from '../components/sections/HeroSection';
import FeatureCardsSection from '../components/sections/FeatureCardsSection';
import {
  ChatDots,
  Stars,
  ArrowCounterclockwise,
  Magic,
  Funnel,
  PersonRaisedHand,
  Soundwave,
  CardChecklist,
} from 'react-bootstrap-icons';

const FeaturesPage = () => {
  const crmLogos = (logos) => {
    return logos.map((logo) => logo);
  };

  return (
    <div>
      <div className="homepage-container">
        <HeroSection
          id="hero1"
          background="radial-gradient(circle, rgba(0, 249, 235, 0.1) 25%, white 80%)"
          color="#333"
          title={`Your ultimate co‑pilot for lead qualification`}
          subtitle={`Discover our innovative features designed to transform\\nlead qualification, automate 24/7 phone calls, and help you\\nconvert prospects faster than ever.`}
          mobileSubtitle="Discover our innovative features designed to transform lead qualification, automate 24/7 phone calls, and help you convert prospects faster than ever."
        />

        <FeatureCardsSection
          id="engagement-feature-cards"
          fontColor="#fff"
          backgroundGradient="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          sectionTitle="Automated Features to Engage Your Leads"
          subtitle="Struggling to keep in touch with leads while they're most interested? We've got that covered."
          demoListItems={[
            'Save time with follow-ups sent over 36 hours',
            'Customize messages to match your brand voice',
            'Use pre-made templates for seamless outreach',
            'Auto-reply to frequently asked questions',
            'Reconnect with past leads effortlessly',
          ]}
          featureCards={[
            {
              title: 'Automated SMS and Email',
              icon: ChatDots,
              description:
                'Each lead is texted and emailed immediately after they show interest. Follow-up messages are sent over 36 hours.',
            },
            {
              title: 'Customizable templates',
              icon: Stars,
              description:
                "Use your company's brand voice in each SMS and Email. Or use our ready-to-go templates for all your automated outreach.",
            },
            {
              title: 'Re-engagement campaign tools',
              icon: ArrowCounterclockwise,
              description:
                'Easily reach out to past leads who never converted or might be ready to reconsider your solutions.',
            },
          ]}
          badgeText="Engagement"
          reverseRow={false}
        />

        <BannerSection
          id="ready-to-2x-your-conversion"
          backgroundColor="#fff"
          color="#000"
          title="Ready to transform your lead qualification?"
          subtitle="Activate our platform with your CRM or ATS to experience the advantages of instant AI conversations."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-purple cta-btn my-4"
        />

        <FeatureCardsSection
          id="conversation-feature-cards"
          fontColor="#fff"
          backgroundGradient="#000"
          sectionTitle="Advanced, Human-Like Conversation Features"
          subtitle="Our experience is designed to feel so human, leads won't even realize they're speaking with AI."
          demoListItems={[
            'Conduct individual conversations simultaneously',
            'Capture interest with 24/7 availability',
            'Eliminate scheduling with on-demand calls',
            'Filter out unqualified leads early',
            'Maintain consistency with structured scripts',
          ]}
          featureCards={[
            {
              title: 'Pre-conversation qualification',
              icon: Funnel,
              description:
                'Identify qualified leads early by verifying essential requirements before deeper engagement.',
            },
            {
              title: 'Customizable conversation scripts',
              icon: Magic,
              description:
                'Set custom intros for each interaction and dynamically ask follow-up questions based on previous responses.',
            },
            {
              title: 'Comprehensive Product and Service Q&A',
              icon: PersonRaisedHand,
              description:
                'Empower our AI assistant to answer prospect questions about your offerings using a knowledge base you provide.',
            },
          ]}
          badgeText="Conversations"
          reverseRow={true}
        />

        <LogoSection
          id="integration-section"
          backgroundColor="#00b3a9"
          color="#fff"
          title="INTEGRATES WITH OVER 100+ PLATFORMS"
          logos={crmLogos(logos)}
        />

        <FeatureCardsSection
          id="automation-feature-cards"
          fontColor="#fff"
          backgroundGradient="radial-gradient(circle, #007D76 0%, rgba(0, 0, 0, 0.8) 50%, black 100%)"
          sectionTitle="CRM Automations That Keep Your Pipeline Updated"
          subtitle="Our CRM automations update lead statuses and conversation outcomes directly in your system, ensuring your data stays accurate and up-to-date."
          demoListItems={[
            'Save time from manually updating your CRM',
            'Customize how your leads are updated',
            'No more switching between multiple platforms',
            'Keep all of your data directly in one place',
            'Easily activate new campaigns and scripts',
          ]}
          featureCards={[
            {
              title: 'AI conversation summaries',
              icon: Stars,
              description:
                'Each lead interaction is summarized and the results are updated automatically in your CRM.',
            },
            {
              title: 'AI conversation transcripts',
              icon: CardChecklist,
              description:
                'Review the full conversation transcript for detailed insights into how leads responded to your questions.',
            },
            {
              title: 'AI call recordings',
              icon: Soundwave,
              description:
                "Listen to the full call recording to assess interest levels and buying signals in your lead's responses.",
            },
          ]}
          badgeText="Automation"
          reverseRow={false}
        />

        <BannerSection
          id="ready-to-2x-your-conversion"
          backgroundColor="#eef"
          color="black"
          title="Don't be the last to use AI for lead qualification"
          subtitle="Qualify 10x more leads automatically while your team focuses on closing deals."
          buttonTitle="Get Started"
          buttonHref="/demo-signup"
          buttonClass="btn btn-ig-purple cta-btn my-4"
        />
      </div>
    </div>
  );
};

export default FeaturesPage;
