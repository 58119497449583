import React from 'react';
import { Button } from 'react-bootstrap';
import {
  ShoppingCart,
  Target,
  Users,
  Building2,
  Calendar,
  Wand,
  Sparkles,
  CheckCircle2,
} from 'lucide-react';
import { TelephoneFill } from 'react-bootstrap-icons';
import './HowItWorksDemoGrid.css';

// Add this component at the top of your file
const ValueProp = ({ children }) => (
  <div className="d-flex align-items-start gap-2 mb-2">
    <CheckCircle2 size={16} style={{ color: '#6f00f9', flexShrink: 0 }} />
    <span className="text-muted small">{children}</span>
  </div>
);

const HowItWorksDemoGrid = ({
  backgroundColor = '#eaedff',
  fontColor = '#000',
  showTitle = true,
}) => {
  // Update demoGridItems array
  const demoGridItems = [
    {
      title: 'Food Service',
      valueProps: [
        'Handle reservations and bookings',
        'Take orders seamlessly and accurately',
        'Collect event or catering details',
        'Share essential business info like hours, parking, and menu options',
        'Operate 24/7, ensuring you never miss an order or reservation',
      ],
      phoneNumber: '208-806-8364',
      icon: (
        <ShoppingCart
          className="feature-icon"
          size={32}
          style={{ color: '#6f00f9' }}
        />
      ),
      image: (
        <img
          src="https://storage.googleapis.com/instahear-react-images/how-it-works-images/pizza-man.jpg"
          alt="Restaurant staff taking phone orders"
          className="w-100 rounded mb-3"
        />
      ),
    },
    {
      title: 'Contractors',
      valueProps: [
        'Never Miss a Job Opportunity',
        'Schedule Appointments Hands-Free',
        'Qualify Leads - Only Take the Projects You Love',
        'Handle Common Customer Questions',
        '24/7 Availability',
        'Recorded & Transcribed Calls',
      ],
      phoneNumber: '434-278-7250',
      icon: (
        <Target
          className="feature-icon"
          size={32}
          style={{ color: '#6f00f9' }}
        />
      ),
      image: (
        <img
          src="https://storage.googleapis.com/instahear-react-images/how-it-works-images/contractor-man.jpg"
          alt="Contractor reviewing project details"
          className="w-100 rounded mb-3"
        />
      ),
    },
    {
      title: 'Hiring Interviews',
      valueProps: [
        'Speed up your Time to Hire',
        'Screen applicants automatically 24/7',
        'Schedule interviews efficiently',
        'Reduce no-shows with consistent engagement',
        'Deliver a consistent interview experience',
        'Eliminate time-wasting phone-tag',
      ],
      phoneNumber: '239-350-3286',
      icon: (
        <Users
          className="feature-icon"
          size={32}
          style={{ color: '#6f00f9' }}
        />
      ),
      image: (
        <img
          src="https://storage.googleapis.com/instahear-react-images/how-it-works-images/recruiter-woman.jpg"
          alt="Healthcare professionals at work"
          className="w-100 rounded mb-3"
        />
      ),
    },
    {
      title: 'Real Estate Services',
      valueProps: [
        'Set up showings for prospective tenants to tour properties',
        'Manage, log, and schedule maintenance requests',
        'Free up your team to focus on urgent matters',
        'Answer calls for business info like parking, hours, and lease details',
        'Ensure no call is missed, even after hours',
      ],
      phoneNumber: '484-276-1634',
      icon: (
        <Building2
          className="feature-icon"
          size={32}
          style={{ color: '#6f00f9' }}
        />
      ),
      image: (
        <img
          src="https://storage.googleapis.com/instahear-react-images/how-it-works-images/property-mgmt-woman.jpg"
          alt="Modern apartment building exterior"
          className="w-100 rounded mb-3"
        />
      ),
    },
    {
      title: 'Booking-Based Businesses',
      valueProps: [
        'Book, reschedule, and cancel appointments automatically',
        'Provide general business info like hours, services, parking',
        'Ensure no call or appointment is missed',
        'Free up your team to focus on in-person interactions',
      ],
      phoneNumber: '484-867-1923',
      icon: (
        <Calendar
          className="feature-icon"
          size={32}
          style={{ color: '#6f00f9' }}
        />
      ),
      image: (
        <img
          src="https://storage.googleapis.com/instahear-react-images/how-it-works-images/barbershop-man.jpg"
          alt="Modern barbershop interior"
          className="w-100 rounded mb-3"
        />
      ),
    },
    {
      title: 'Custom Experience',
      valueProps: [
        'Design your ideal qualification flow',
        'Create custom response handling',
        'Eliminate wasted time on unqualified calls',
        'Unlock analytics to grow your business',
        'Automate repetitive tasks',
        'Deliver a consistent brand experience',
      ],
      icon: (
        <Wand className="feature-icon" size={32} style={{ color: '#6f00f9' }} />
      ),
      customButton: (
        <Button
          href="/demo-signup"
          size="sm"
          className="btn-ig-purple demo-button text-start d-flex align-items-center gap-2"
          style={{ width: '100%' }}
        >
          <Sparkles size={16} />
          Create Your Own
        </Button>
      ),
      image: (
        <img
          src="https://storage.googleapis.com/instahear-react-images/how-it-works-images/custom-woman.jpg"
          alt="Modern barbershop interior"
          className="w-100 rounded mb-3"
        />
      ),
    },
  ];

  return (
    <section
      className="how-it-works-demo-grid-section"
      style={{ background: backgroundColor, color: fontColor }}
    >
      <div className="container desktop-container">
        {showTitle && (
          <div className="text-center mb-5">
            <h2 className="display-5 fw-bold mb-2 mb-lg-4">How It Works</h2>
            <p className="fs-4">
              Easy to manage, cost-efficient call screening that grows with your
              business.
              <br />
              No contracts. Only pay for what your use.
            </p>
          </div>
        )}

        <div className="row g-4 justify-content-center">
          {demoGridItems.map((feature, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <div className="card h-100 border-0 shadow-sm">
                {feature.image}
                <div className="card-body p-4 d-flex flex-column">
                  <h3 className="h4 fw-bold mb-3">{feature.title}</h3>
                  <div className="flex-grow-1">
                    {feature.valueProps.map((prop, index) => (
                      <ValueProp key={index}>{prop}</ValueProp>
                    ))}
                  </div>
                  <div className="card-footer border-0 bg-transparent p-0 mt-3">
                    {feature.customButton ? (
                      feature.customButton
                    ) : (
                      <div className="d-flex flex-column gap-2">
                        <p class="fw-bold text-center">
                          Try It Now, Get 300 Free Minutes 🎁
                        </p>
                        <Button
                          href={`tel:${feature.phoneNumber}`}
                          className="btn-ig-purple w-100 text-center py-2 d-flex align-items-center justify-content-center gap-2"
                        >
                          <TelephoneFill size={12} />
                          {feature.phoneNumber}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style>
        {`
          .feature-icon {
            display: inline-block;
          }
          .card {
            transition: transform 0.2s ease-in-out;
          }
          .card:hover {
            transform: translateY(-5px);
          }
        `}
      </style>
    </section>
  );
};

export default HowItWorksDemoGrid;
